@import "./var";

.iconGen(@icon,@width:10px,@height:10px){
  background: url("@{icon_url}@{icon}.png") no-repeat center;
  width: @width;
  height: @height;
  background-origin:content-box;
  // background-size: contain;
  background-size: @width @height;
  display: inline-block;
  flex-shrink: 0;
}

.icon-success{
  .iconGen(icon-success,50px,50px);
}

.icon-close{
  .iconGen(icon-close,48px,48px);
}

.icon-story-list{
  .iconGen(icon-story-list,24px,27px);
}


.icon-two-heart{
  .iconGen(icon-two-heart,26px,21px);
}

.icon-two-house{
  .iconGen(icon-two-house,22px,22px);
}

.icon-index-swiper-left{
  .iconGen(icon-index-swiper-left,60px,60px);
}

.icon-info-circle_gray{
  .iconGen(icon-info-circle_gray,16px,16px);
  background-size: 14px 14px;
}

.icon-checked_blue{
  .iconGen(icon-checked_blue,16px,16px);
}

.icon-loading{
  .iconGen(icon-loading,24px,24px);
}

.icon-allow-left{
  .iconGen(icon-allow-left,12px,21px);
}

.icon-wish-topic{
  .iconGen(icon-wish-topic,22px,26px);
}

.icon-co-compony-left{
  .iconGen(icon-co-compony-left,30px,30px);
}

.icon-co-compony-right{
  transform: rotateZ(180deg);
  .icon-co-compony-left;
}

.icon-wish-angel-list{
  .iconGen(icon-wish-angel-list,26px,27px);
}

.icon-question-mask{
  .iconGen(icon-question-mask,15px,15px);
}

.icon-spinner-up{
  .iconGen(to_up,11px,6px);
}

.icon-spinner-down{
  .iconGen(to_down,11px,6px);
}

.icon-select-drow{
  .iconGen(select-drow,10px,7px);
}

.icon-clock{
  .iconGen(clock,14px,14px);
}

.icon-people{
  .iconGen(people,13px,14px);
}

.icon-address{
  .iconGen(address,14px,18px);
}

.icon-search{
  .iconGen(search,21px,22px);
}

.icon-msg{
  .iconGen(icon-msg, 14px, 14px);
}

.icon-orange-add-item{
  .iconGen(orange-add-item, 30px, 30px);
}

.icon-orange-remove-item{
  .iconGen(orange-remove-item, 30px, 30px);
}

.icon-submit-success{
  .iconGen(icon-submit-success, 65px, 64px);
}

