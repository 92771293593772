@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.page-pb{
  padding-bottom: 85px;
}

.home {
	.stat-list {
		background-color: #fafafa;
		border: 1px solid #ededed;
		border-radius: 4px;
		color: @black;
		padding-top: 17px;
		padding-bottom: 17px;
		margin-bottom: 25px;
		overflow: hidden;

		li {
			padding-left: 40px;
			box-sizing: border-box;
			float: left;
			align-items: center;
			width: 33.33%;
			overflow: hidden;

			& + li {
				border-left: 1px solid #a2df44;
			}

			.content {
				.count {
					font-size: 24px;
				}

				.title {
					font-size: 16px;
				}
			}
		}
	}

	.sidebar {
		.news, .topic {
			border: 1px solid;
			padding-top: 20px;

			> .title {
				overflow: hidden;
				margin-bottom: 0;
				line-height: 1.2;
				height: auto;

				.more {
					display: inline-block;
				}

				.title-under-line {
					overflow: hidden;
					border-bottom: 0;
					line-height: 24px;
					height: auto;
					margin-bottom: 0;

					h3 {
						height: auto;
						border-bottom: none;
						line-height: 1.2;
						// .title-left-deco;
						font-weight: 400;
						font-size: 24px;
					}
				}

			}
		}
	}
}

.volunteer-apply-page{
  width: 840px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  padding-top: 0;
  margin-bottom: 95px;
  @field_name_width: 105px;

  .title-bar{
	font-weight: bold;
	font-size: 18px;
	color: @black;
	padding-bottom: 20px;
	border-bottom: 1px solid @border;
	margin-bottom: 15px;
	text-align: center;
  }
  .title-bar-wrapper-tips{
	font-size: 14px;
	color: @gray;
	line-height: 1.5;
	margin-bottom: 20px;
  }
  .qs-form{
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	padding-bottom: 110px;
	.from-group-wrapper{
	  display: flex;
	  flex-wrap: wrap;
	  justify-content: space-between;
	}
	.form-group-inline,.form-group{
	  width: 100%;
	  margin-bottom: 20px;
	  .field-name{
		width: @field_name_width;
	  }
	}
	.form-group-half{
	  width: 375px;
	}
	.upload-img{
	  margin-top: -10px;
	  margin-bottom: 10px;
	}
	.join-time{
	  margin-bottom: 15px;
	  padding-top: 25px;
	  border-top: 1px solid @border;
	  .field-name{
		margin-top: 0;
	  }
	  .form-field{
		font-size: 14px;
		display: flex;
		flex-wrap: wrap;
		label{
		  cursor: pointer;
		  display: inline-flex;
		  align-items: center;
		  margin-top: 0;
		  margin-bottom: 15px;
		  width: 98px;
		  margin-right: 0;
		  input[type=checkbox]{
			width: 15px;
			height: 15px;
		  }
		  .text{
			margin-left: 5px;
		  }
		}
	  }
	}
	.btn-submit{
	  margin-left: @field_name_width + 10px;
	  .btn.btn-m;
	  width: 130px;
	}
  }
}

.info-list{
	>li{
		padding: 20px 0;
		border-bottom: 1px solid @border;
		&:hover{
			.title{
				color: @red;
			}
		}
		.title{
			font-size: 18px;
			color: @black;
			line-height: 1.5;
			.text-overflow;
			margin-bottom: 10px;
		}
		.bottom-wrap{
			font-size: 14px;
			color: @gray;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.left-wrap{
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex-shrink: 0;
				width: 525px;
			}
			.wish-summary{
				display: flex;
				align-items: center;
				width: 310px;
				.wish-summary-item{
					width: 155px;
					flex-shrink: 0;
				}
			}
			.item-desc{
				width: 250px;
				flex-shrink: 0;
			}
			.item-time{
				flex-shrink: 0;
			}
			.item-donate-amount{
				flex-shrink: 0;
			}
			.item-wish-status{
				flex-shrink: 0;
				color: @blue;
				flex-grow: 1;
				text-align: right;
			}
			.btn-wrap{
				flex-grow: 1;
				display: flex;
				justify-content: flex-end;
				align-items: center;
				a,[data-href]{
					display: inline-block;
					color: @black;
					padding-left: 10px;
					padding-right: 10px;
					&:hover{
						color: @red;
					}
					&:first-child{
						padding-left: 0;
					}
					&:last-child{
						padding-right: 0;
					}
					& + a{
						border-left: 1px solid @border;
					}
				}
			}
		}
	}
}


.user-profile-page{
  .top-menu{
	margin-bottom: 40px;
  }
}

#comment-bottom-popup{
  .popup-footer{
	display: flex;
	justify-content: flex-end;
   margin: 0 auto;
	a{
	  & + a{
		margin-left: 20px;
	  }
	}
  }
}


@media screen and (min-width: 1480px) {
  .index-swiper-wrapper{
	width: 1440px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	position: relative;
	&.processing{
	  .radius-deco{
		transform: scale(0);
	  }
	}
	.radius-deco{
	  position: absolute;
	  width: 25px;
	  height: 25px;
	  background-color: #4ecc33;
	  z-index: 998;
	  transition: transform .3s;
	  transform: scale(1);
	  &.left{
		left: 0;
	  }
	  &.right{
		right: 0;
	  }
	  &.top{
		top: 50px;
	  }
	  &.bottom{
		bottom: 80px;
	  }
	  &.left-top{
		.bg(index-swiper-deco-left-top,png,center, 25px 25px);
		transform-origin: left top;
	  }
	  &.right-top{
		.bg(index-swiper-deco-right-top,png,center, 25px 25px);
		transform-origin: right top;
	  }
	  &.left-bottom{
		.bg(index-swiper-deco-left-bottom,png,center, 25px 25px);
		transform-origin: left bottom;
	  }
	  &.right-bottom{
		.bg(index-swiper-deco-right-bottom,png,center, 25px 25px);
		transform-origin: right bottom;
	  }
	}
  }
}
@media screen and (max-width: 1480px) {
  .index-swiper-wrapper{
	.container;
	overflow: hidden;
	position: relative;
  }
  .swiper-area{
	.container;
  }
}


.page-index{
  margin-top: 30px;
  .swiper-area{
	position: relative;
	.btn-swiper-button-prev,.btn-swiper-button-next{
	  position: absolute;
	  top: 50%;
	  margin-top: -45px;
	  z-index: 2;
	  cursor: pointer;
	  border-radius: 25px;
	}
	.btn-swiper-button-prev{
	  left: 120px;
	}
	.btn-swiper-button-next{
	  right: 120px;
	  transform: rotatey(180deg);
	}
  }
  .index-swiper{
	overflow: unset;
	position: relative;
	width: 1080px;
	margin: 0 auto 30px;
	.swiper-slide{
	  &.swiper-slide-active{
		a{
		  display: block;
		  height: 100%;
		  max-width: 1080px;
		  overflow: hidden;
		  border-radius: 4px;
		  box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
		}
	  }
	  &.swiper-slide-prev,&.swiper-slide-next{
	  }
	  &.swiper-slide-prev{
	  }
	  &.swiper-slide-next{
	  }
	}
  }

  .block-panel{
	padding-top: 45px;
  }

  .topic-list{
	margin-left: -25px;
	margin-bottom: -25px;
	display: flex;
	align-items: center;
	>li{
	  border: 1px solid @border;
	  width: 344px;
	  height: 310px;
	  border-radius: 4px;
	  overflow: hidden;
	  margin-left: 25px;
	  margin-bottom: 25px;
	  transition: all .3s;
	  &:hover{
		border-color: transparent;
		box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
		.content{
		  .title{
			color: @red;
		  }
		}
	  }
	  a{
		display: flex;
		flex-direction: column;
	  }
	  .img{
	    position: relative;
		.img-wrap(100%, 222px);
		  .tag{
			  position: absolute;
			  left: 0;
			  top: 0;
		  }
	  }
	  .content{
		padding: 15px;
		display: flex;
		flex-direction: column;
		.title{
		  font-size: 18px;
		  color: @black;
		  margin-bottom: 10px;
		  .text-overflow;
		}
	  }
	}
  }

  .store-and-rank{
	display: flex;
	justify-content: space-between;
	.store{
	  width: 670px;
	  .title-bar__more__wrapper{
		display: flex;
		align-items: center;
		.title-bar__more{
		  & + .title-bar__more{
			margin-left: 40px;
		  }
		  &.active{
			color: @red;
		  }
		}
	  }
	  .story-list-wrapper{
		.story-list{
		  .list(210px, 136px, 20px, 25px);
		  >li{
			& + li{
			  margin-top: 25px;
			}
			a{
			  height: 136px;
			  border: 1px solid #ededed;
			  border-radius: 5px;
			  padding-right: 30px;
			  &:hover{
				box-shadow: 0 0 20px #ddd;
			  }
			  .img{
				border-radius: 4px 0 0 4px;
			  }
			  .content{
			    height: 136px;
			    padding: 20px 0;
				justify-content: space-between;
				.title{
				  .text-overflow;
				}
			  }
			}
		  }
		}
	  }
	}
	.rank{
	  width: 372px;
	  .ambassador-rank-list{
		font-size: 16px;
		>li{
		  display: flex;
		  align-items: center;
		  & + li{
			margin-top: 20px;
		  }
		  &:nth-child(-n + 3){
			.num{
			  color: @red;
			  background-color: #ededed;
			}
		  }
		  .num{
			color: @gray;
			width: 22px;
			height: 22px;
			margin-right: 15px;
			.flex-center;
		    white-space: nowrap;
		  }
		  .content{
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-grow: 1;
			.content-left{
			  display: flex;
			  align-items: center;
			  .avatar{
				.avatar(28px);
				margin-right: 10px;
			  }
			  .name{
				color: @black;
				max-width: 150px;
				.text-overflow;
			  }
			}
			.points{
			  color: @red;
			}
		  }
		}
	  }
	}
  }

  .swiper-cooper-logo-wrapper{
	position: relative;
	.cooper-logo {
	  display: flex;
	  justify-content: space-between;
	  height: 84px;
	  box-sizing: border-box;
	  position: relative;
	  .logo-box {
		width: 200px;
		display: flex;
		justify-content: center;
		align-items: center;
		box-sizing: border-box;
		a {
		  border: 1px solid @border;
		  padding: 5px 10px;
		  height: 100%;
		  width: 100%;
		  text-align: center;
		  display: flex;
		  justify-content: center;
		  align-items: center;
		  img{
			width: auto;
		  }
		}
	  }
	}
	.swiper-page-btn{
	  position: absolute;
	  top: 50%;
	  margin-top: -15px;
	  width: 30px;
	  height: 30px;
	  z-index: 999;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  cursor: pointer;
	  i{
		box-shadow: 0 1px 2px 0 rgba(71, 6, 5, 0.08);
		border-radius: 50%;
	  }
	  &.swiper-btn-prev{
		left: -40px;
	  }
	  &.swiper-btn-next{
		right: -40px;
		i{
		  transform: rotatey(180deg);
		}
	  }
	  &.swiper-button-disabled{
		opacity: .3;
		cursor: default;
	  }
	}
  }

}

@media screen and (max-width: 1200px) {
  .page-index{
	.swiper-cooper-logo-wrapper{
	  .swiper-page-btn{
		&.swiper-btn-prev{
		  left: 10px;
		}
		&.swiper-btn-next{
		  right: 10px;
		}
	  }
	}
  }
}

.wish-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -30px;
  >li{
	margin-left: 20px;
	margin-bottom: 30px;
	border-radius: 4px;
	transition: all .3s;
	width: 255px;
	&:hover{
	  box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
	  a{
		.content{
		  border-color: transparent;
		  .top{
			.title{
			  color: @red;
			}
		  }
		}
	  }
	}
	a{
	  display: flex;
	  flex-direction: column;
	  align-items: flex-start;
	  .img{
		border: 1px solid rgb(229, 229, 229);
		border-bottom: 0;
		position: relative;
		.img-wrap(100%, 165px);
		border-radius: 4px 4px 0 0;
		.tag{
		  position: absolute;
		  left: 0;
		  top: 0;
		}
	  }

	  .content{
		height: 108px;
		width: 100%;
		padding: 15px;
		border: 1px solid @border;
		border-top: 0;
		border-radius: 0 0 4px 4px;
		.top{
		  .title{
			margin-bottom: 5px;
			font-size: 18px;
			span{
			  .text-overflow;
			  width: 223px;
			  vertical-align: middle;
			}
		  }
		  .area{
		  }
		}
		.process-wrapper{
		  margin-bottom: 10px;
		}
		.bottom{
		  font-size: 14px;
		}
	  }
	}
  }
}

.partner-org-list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-bottom: -30px;
  >li{
	@width: 200px;
	@height: 85px;
	margin-bottom: 30px;
	border-radius: 4px;
	border: 1px solid @border;
	margin-left: 20px;
	.img-wrap(@width, @height);
	a{
	  .img-wrap(@width, @height);
	  .flex-center;
	  //无链接
	  cursor: default;
	}
  }
}


.page-wish-index{
  padding-top: 30px;
  .top-menu{
	margin-bottom: 20px;
  }
  .wish-status-box{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
    margin-top: 5px;
	.select-menu-list{
	  margin-top: 5px;
	}
	.qs-search-form{
	  margin-bottom: 0;
	  width: 670px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
		margin-top: -3px;
	}
	  .input-range-wrapper{
		  align-items: flex-end;
	  }
  }
}

.qs-search-form{
  margin-bottom: 25px;
  > .input-default {
	margin-left: 10px;
	margin-bottom: 0;
	position: relative;
	> input {
	  width: 320px;
	  height: 36px;
	  border: 1px solid @border;
	  padding-right: 30px;
	}
	> .search-icon {
	  position: absolute;
	  right: 0;
	  top: 0;
	  background-image: unset;
	  padding: 0 6px;
	  border: none;
	  outline: none;
	  background: none;
	  cursor: pointer;
	  height: 100%;
	}
  }
  .select-default {
	border: 1px solid @border;
	padding: 0 10px;
	margin-bottom: 0;
  }
}

.page-wish-detail{
  .detail-summary{
	.cover{
	  position: relative;
	  .tag{
		position: absolute;
		left: 0;
		top: 0;
	  }
	}
	.content{
	  .meta{
		padding-bottom: 15px;
		border-bottom: 1px solid @border;
		.btn-wish-share{
		  padding-left: 15px;
		  padding-right: 15px;
		}
	  }
	  .raise-list{
		padding-top: 20px;
		padding-bottom: 15px;
	  }
	}
  }
  .content-wrapper{
	.main-content-wrapper{
	  .top-menu{
		.tab-menu{
		}
	  }
	  .content-list{
		.wish-detail-list{
		  .title-desc-item{
			margin-bottom: 40px;
		  }
		}
		.wish-detail{
		  position: relative;
		  .audit-img{
			position: absolute;
			right: 0;
			top: -10px;
		  }
		}
	  }
	}
	aside{

	}
  }
}

.page-fund-detail {
  .detail-summary{
	.content{
	  .meta{
		padding-bottom: 15px;
		border-bottom: 1px solid @border;
	  }
	  .fund-count-wrap{
		padding-top: 20px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: -5px;
		.fund-count-column{
		  flex-grow: 1;
		  margin-bottom: 5px;
		  &:first-child{
			width: 219px;
		  }
		  &:nth-child(2){
			width: 213px;
		  }
		  &:last-child{
			.fund-count-item{
			  justify-content: flex-end;
			}
		  }
		  .fund-count-item{
			flex-shrink: 0;
			display: flex;
			align-items: baseline;
			.desc{
			  font-size: 14px;
			  color: @light-black;
			}
			.sum{
			  color: @red;
			  font-size: 18px;
			}
		  }
		}
	  }

	  .bottom{
		height: 113px;
		margin-top: 16px;
		padding-top: 15px;
		.bottom-left{
		  .bottom-left__top{

		  }
		}
		.btn-group{
		  width: 135px;
		  height: 35px;
		}
	  }
	}
  }
  .content-wrapper{
	.main-content-wrapper{
	  .msg-list{
		>li{
		  &:first-child{
			padding-top: 0;
		  }
		}
	  }
	}
  }
}


.page-login-tips{
  padding: 100px 0;
  main{
	.bg(bg-donate-cert,jpg);
	width: 520px;
	margin: auto;
	height: 580px;
	flex-grow: 0;
	padding-top: 45px;
	padding-bottom: 80px;
	box-shadow: 0 8px 18px 0 rgba(0, 0, 0, 0.15);
	.logo-img{
	  text-align: center;
	  margin-bottom: 35px;
	  img{
		height: 60px;
	  }
	}
	.content{
	  box-shadow: 0 8px 36px 0 rgba(21, 1, 1, 0.2);
	  width: 330px;
	  height: 355px;
	  padding: 30px 60px 31px;
	  margin-left: auto;
	  margin-right: auto;
	  background-color: #fff;
	  border-radius: 4px;
	  .flex-center;
	  flex-direction: column;
	  text-align: center;
	  font-size: 14px;
	  line-height: 1.5;
	  .qr_code_img{
		margin-bottom: 60px;
		width: 190px;
		height: 190px;
		img{
		  height: 100px;
		}
	  }
	}
  }
}

.page-wish-share{
  padding-top: @top_space;
  padding-bottom: @bottom_space;
  .wish-share-wrapper{
	width: 720px;
	margin: auto;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	margin-bottom: 55px;
	overflow: hidden;
	box-shadow: 0 8px 36px 0 rgba(21, 1, 1, 0.07);
	.wish-cover{
	  .img-wrap(100%, 392px);
	  position: relative;
	  .logo{
		position: absolute;
		top: 25px;
		right: 20px;
		height: 70px;
	  }
	  .tag{
		position: absolute;
		left: 0;
		top: 0;
	  }
	}
	.wish-share-content{
	  height: 245px;
	  background-color: #fff;
	  padding: 33px 60px;
	  display: flex;
	  align-items: flex-start;
	  justify-content: space-between;
	  border-radius: 0 0 4px 4px;
	  .left-size{
		min-height: 154px;
		margin-right: 70px;
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		.left-size-title{
		  font-size: 22px;
		  color: @black;
		  line-height: 1.6;
		  min-height: 106px;
		}
		.left-size-desc{
		  line-height: 1.5;
		  font-size: 20px;
		  color: @light-black;
		}
		.wish-owner{
		  border-top: 1px solid @border;
		  line-height: 1.5;
		  font-size: 18px;
		  color: @gray;
		  padding-top: 26px;
		  margin-top: 23px;
		}
	  }
	  .right-size{
		height: 154px;
		text-align: center;
		.img{
		  .img-wrap(123px,123px);
		}
		.desc{
		  white-space: nowrap;
		  font-size: 18px;
		  color: @gray;
		  line-height: 1.5;
		}
	  }
	}

  }
  .btn-group{
	justify-content: center;
    margin-bottom: 30px;
	.btn{
	  height: 28px;
	  width: 100px;
	  flex-grow: 0;
	}
  }
  .vip-desc{
	  text-align: center;
	  font-size: 14px;
	  color: @light-black;
  }
}

.page-donate-index{
  padding-bottom: @bottom_space;
  main{
	width: 840px;
	margin: 0 auto;
  }
  .page-panel{
	padding-bottom: 30px;
	.donate-content{
	  width: 665px;
	}
	.input-unit{
	  .unit{
		right: -30px;
	  }
	}
	.top-menu{
	  justify-content: flex-start;
	  font-size: 18px;
	  color: @black;
	  margin-bottom: 12px;
	}
	.donate-desc{
	  font-size: 14px;
	  color: @gray;
	  margin-bottom: 15px;
	}
	.money-list{
	  display: flex;
	  align-items: center;
	  font-size: 14px;
	  color: @black;
	  margin-left: -20px;
	  margin-bottom: 20px;
	  >li{
		margin-left:20px;
		transition: all .3s;
		cursor: pointer;
		height: 40px;
		.flex-center;
		border-radius: 4px;
		background-color: @input-bg-color;
		width: 120px;
		&.active{
		  color: #fff;
		  background-color: @red;
		}
	  }
	}
	.checkbox-agree{
	  width: auto;
	  font-size: 14px;
	  input[type=checkbox]{
		width: 17px;
		height: 17px;
	  }
	  .checkbox-agree__desc{
		margin-left: 10px;
	  }
	}
	.secret-name{
	  margin-top: -10px;
	  label{
		margin-top: 0;
	  }
	}
	.is-need-bill{
	  label{
		margin-top: 0;
	  }
	  >label{
		display: inline-flex;
		align-items: center;
	  }
	  .form-field{
		display: flex;
		align-items: center;
		label{
		  cursor: pointer;
		  display: inline-flex;
		  align-items: center;
		  font-size: 14px;
		  input[type=radio]{
			width: 24px;
			height: 24px;
		  }
		  &.need-bill{
			margin-right: 50px;
		  }
		  &.not-need-bill{
			margin-right: 15px;
		  }
		}
		a{
		  font-size: 14px;
		  color: @red;
		  border-bottom: 1px dashed @red;
		}
	  }
	}
	.receipt-wrapper{
	  margin-top: -10px;
	}
	.donate-msg{
	  padding-bottom: 30px;
	}
	.think-desc,.donate-msg{
	  textarea{
		height: 85px;
		padding: 20px;
		line-height: 1.5;
	  }
	}
	.think-images{
	  .ossuploader-add{
		margin-bottom: 3px;
	  }
	}
	.donate-agree{
	  label{
		width: auto;
		margin-top: 0;
		cursor: pointer;
	  }
	}
	.btn-submit{
	  width: 130px;
	  padding: 0;
	}
  }
}

.page-donate-success{
  padding-bottom: @bottom_space;
  .donate-wrapper{
	position: relative;
	padding-top: 70px;
	min-height: 520px;
	padding-left: 100px;
	padding-right: 100px;
	padding-bottom: 60px;
	.bg(donate_cert_bg,jpg,center, 100% 100%);
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 8px 36px 0 rgba(21, 1, 1, 0.2);
	width: 720px;
	margin: 0 auto  50px;
	color: #575551;
	.donate-success-title{
	  height: 51px;
	  color: #cb8d0e;
	  font-size: 42px;
	}
	.cert-no{
	  color: #cb8d0e;
	  text-align: center;
	  font-size: 18px;
	  margin-top: 10px;
	}
	.desc{
	  margin-top: 30px;
	  line-height: 1.5;
	  &.donator-name{
		font-size: 22px;
		color: #333230;
	  }
	  &.thanking{
		margin-top: 20px;
		font-size: 18px;
		margin-bottom: 10px;
		text-indent: 2em;
	  }
	  &.thanking-tips{
		text-align: center;
		color: #cb8d0e;
		font-size: 28px;
		margin-top: 30px;
	  }
	}
	.bottom{
	  margin-top: 30px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  text-align: right;
	  .qr-code{
		background-color: #fff;
		.img-wrap(120px, 120px);
	  }
	  .donate-info{
		line-height: 1.5;
		font-size: 18px;
		position: relative;
		color: #444038;
		.love-cert{
		  position: absolute;
		  width: 120px;
		  height: 120px;
		  z-index: 2;
		  top: 50%;
		  left: 50%;
		  transform: translate(-50%,-50%);
		}
		.org{
		  margin-bottom: 5px;
		}
	  }
	}
  }
  .btn-group{
	justify-content: center;
	.btn{
	  width: 100px;
	  height: 28px;
	  flex-grow: 0;
	  & + .btn{
		margin-left: 70px;
	  }
	}
  }
}

.ajax-page-loading{
  position: relative;
  @keyframes rotate {
	from {transform: rotate(0);}
	to {transform: rotate(360deg);}
  }

  &.ajax-page-loading__active{
	.ajax-page-loading-icon,.loading-mask{
	  display: block;
	}
  }

  .ajax-page-loading-icon{
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -12px;
	margin-top: -12px;
	z-index: 11;
	animation: rotate 1s infinite linear;
	.icon-loading;
	display: none;
  }
  .loading-mask{
	background-color: #fff;
	opacity: .3;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 10;
	display: none;
  }
}

.user-profile-page{
  .ossuploader-filedelete{
	display: none;
  }
}

.article-content{
  iframe{
  }
}

.page-theme-index{
  padding-bottom: 150px;
  padding-top: 30px;
  .top-menu{
	margin-bottom: 30px;
  }
}

.page-theme-detail{
  .detail-summary{
	.content{
	  .raise-list{
		margin-bottom: 20px;
		padding-bottom: 25px;
		border-bottom: 1px solid @border;
	  }
	  .summary-title{
		font-size: 16px;
		color: @black;
		margin-bottom: 10px;
	  }
	  .summary-content{
		font-size: 14px;
		color: @light-black;
	  }
	}
  }
  .tab-content{
	.qs-search-form{
	  display: flex;
	  align-items: center;
	  .wish-status-list{
		margin-right: 90px;
	  }
	  .input-default{
		margin-left: 0;
		input{
		  padding-left: 10px;
		}
	  }
	}
	.wish-list{
	  >li{
		width: 242px;
		a{
		  >.img{
			height: 157px;
		  }

		}
	  }
	}
  }
}


.page-story-index{
  padding-top: 30px;
  .banner{
	margin-bottom: 25px;
	overflow: hidden;
	&:hover{
	  img{
		transform: scale(1.01);
	  }
	}
	img{
	  transition: all 3s;
	}
  }
  .story-list-wrapper{
	float: left;
	padding: 15px 20px;
	background: white;
	width: 750px;
	margin-right: 30px;
	.top-menu{
	  margin-bottom: 28px;
	}
	.story-list{
	  .list(258px, 168px, 30px, 30px);
	  margin-bottom: 20px;
	  >li{
		border-radius: 4px;
		a{
		  height: 168px;
		  .img{
			border-radius: 4px;
		  }
		  .content{
			padding: 12px 0;
			border-bottom: 1px solid @border;
			.title{
			  line-height: 1.8;
			  height: 60px;
			  .text-limit2;
			  margin-bottom: 12px;
			}
			.time{
			  margin-bottom: 17px;
			}
		  }
		}
	  }
	}
  }
}
.page-org-index,.page-vip-index{
  padding-top: 25px;
  .main-content-wrapper{
	.vip-table{
		width: 100%;
	  thead{
		font-size: 14px;
		color: @light-black;
		text-align: left;
		tr{
		  th{
			padding-bottom: 20px;
		  }
		}
	  }
	  tbody{
		font-size: 16px;
		tr{
		  &.not-vip{
			background-color: #f5f7fa;
		  }
		  &:nth-child(-n + 3){
			.rank{
			  .num{
				color: @red;
				background-color: #ededed;
			  }
			}
		  }
		  td{
		    padding-bottom: 10px;
			vertical-align: middle;
			&.points{
			  color: @red;
			}
			&.rank{
				.num,.text{
					width: 20px;
					height: 20px;
					.flex-center;
					padding-left: 4px;
				}
			  .num{
				color: @light-black;
			  }
			  .text{
				  width: auto;
				  justify-content: flex-start;
			  }
			}
		  }
		}
	  }
	  .rank{
		width: 60px;
	  }
	  .user-profile{
		width: 350px;
		display: inline-flex;
		align-items: center;
		.avatar{
		  .avatar(28px);
		}
		.user-name{
		  margin-left: 10px;
		  max-width: 250px;
		}
	  }
	  .code{
		width: 200px;
	  }
	  .points{
		text-align: right;
		padding-right: 2px;
		width: 105px;
	  }
	}
	.explain-text{
	  color: @gray;
	  font-size: 14px;
	  text-align: right;
	  padding: 10px 0;
	}
  }
}

.page-vip-add{
  padding-top: 25px;
  .page-panel{
	width: 840px;
	margin-left: auto;
	margin-right: auto;
	.top-menu{
	  margin-bottom: 10px;
	}
	.title-bar-wrapper-tips{
	  font-size: 14px;
	  color: @gray;
	  margin-top: 10px;
	  margin-bottom: 30px;
	}
  }
}

.page-vip-apply-success{
  .panel{
	width: 620px;
	background-color: #fff;
	box-shadow: 0px 6px 20px 0px rgba(71, 6, 5, 0.08);
	margin: 90px auto 0;
	padding: 47px 58px 54px;
	text-align: center;
	.title{
	  font-size: 24px;
	  color: @black;
	  margin-bottom: 17px;
	}
	.desc{
	  font-size: 14px;
	  color: @light-black;
	  margin-bottom: 25px;
	}
	.qr-code-wrapper{
	  border: 1px solid @border;
	  width: 256px;
	  padding: 0 5px 30px 5px;
	  margin-left: auto;
	  margin-right: auto;
	  margin-bottom: 40px;
	  .img{
		// margin-bottom: 15px;
		padding: 20px;
		.img-wrap(100%, 100%);
	  }
	  .code{
		font-size: 14px;
		color: @gray;
	  }
	  .btn-save-img {
		background-color: transparent;
		  cursor: pointer;
		  font-size: 18px;
		  color: #6483bb;
		&.gray {
			cursor: default;
			color: #666;
		}
	  }
	}
	.btns{
	  width: 320px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  margin-top: 40px;
	  margin-left: auto;
	  margin-right: auto;
	  .btn{
		height: 25px;
		font-size: 14px;
	  }
	}
  }
}
.page-exchange-index,.page-org-index{
	.rank-box{
		font-size: 16px;
		display: flex;
		align-items: center;
		i{
			color: @red;
		}
		&__title{
			color: @black;
			margin-left: 5px;
			margin-right: 10px;
		}
		&__item{
			color: @light-black;
			cursor: pointer;
			& + .rank-box__item{
				margin-left: 15px;
			}
			&__active, &:hover{
				color: @red;
			}
		}
	}
}
.page-exchange-index{
  padding-top: 25px;
  .main-content-wrapper{
	.top-menu{
	  margin-bottom: 18px;
	  .right{
		.icon-desc-item{
		  &.show-points-reason{
			cursor: pointer;
		    margin-left: 20px;
		  }
		}
	  }
	}
	.price-list{
	  margin-top: 20px;
	}
  }
}

#exchange-popup{
  padding: 30px;
	min-width: 800px;
  .exchange-info-box{
	.exchange-info{
	  display: flex;
	  border-bottom: 1px solid @border;
	  padding-bottom: 38px;
	  margin-bottom: 15px;
	  .img{
		.img-wrap(320px, 210px);
		margin-right: 40px;
	  }
	  .content{
		flex-grow: 1;
		padding-top: 5px;
		.title{
		  font-size: 24px;
		  color: @black;
		  line-height: 1.5;
		  margin-bottom: 11px;
		  .text-limit2;
		}
		.price-info{
		  margin-bottom: 12px;
		  max-width: 355px;
		}
		.price-count{
		  margin-bottom: 20px;
		  display: flex;
		  align-items: center;
		  .title-desc-item{
			align-items: center;
			& + .title-desc-item{
			  margin-left: 15px;
			}
		  }
		}
		.bottom{
		  display: flex;
		  align-items: center;
		  .btn{
			margin-right: 10px;
			width: 130px;
			height: 36px;
			font-size: 14px;
		  }
		}
	  }
	}
	.price-desc{
	  .title{
		font-size: 16px;
		color: @black;
		margin-bottom: 10px;
	  }
		.remark-box{
			max-height: 180px;
			overflow-y: hidden;
			.desc{
				font-size: 14px;
				color: @gray;
				line-height: 1.5;
			}
		}

	}
  }
  .popup-content{
	margin-top: 0;
	margin-bottom: 0;
  }
}

.page-exchange-detail{
  padding-top: 25px;
  .page-panel{
	width: 850px;
	.margin-center;
	.top-menu{
	  margin-bottom: 35px;
	}
	.base-info{
	  border-bottom: 1px solid @border;
	  margin-bottom: 25px;
	  width: 665px;
	  .area-select{
		margin-bottom: 10px;
	  }
	}
	.price-info{
	  margin-bottom: 28px;
	  .price-name{
		width: 315px;
	  }
	  .price-price{
		width: 225px;
	  }
	  .price-num{
		width: 168px;
	  }
	  .price-count{
	  }
	}
	.price-desc{
	  .title{
		font-size: 16px;
		color: @black;
		margin-bottom: 10px;
	  }
	  .desc{
		font-size: 14px;
		color: @gray;
		line-height: 1.5;
	  }
	}
	.btn-box{
	  margin-top: 60px;
	  text-align: center;
	  .btn{
		height: 35px;
	  }
	}
  }
}

#ambassador-recommend-popup{
  width: 420px;
  padding-bottom: 0;
  .recommend-qr-code-wrapper{
	text-align: center;
	.recommend-qr-code{
	  padding-top: 20px;
	  padding-bottom: 10px;
	  width: 300px;
	  margin-left: auto;
	  margin-right: auto;
	  .img{
		.img-wrap(185px, 185px);
		margin-bottom: 15px;
	  }
	  .desc{
		font-size: 12px;
		color: @gray;
	  }
	}

	.btn{
	  margin-top: 30px;
	}
  }
}

.page-user-exchange-list{
  .top-menu{
	margin-bottom: 17px;
	  .top-menu-right{
		  .show-points-reason{
			  cursor: pointer;
		  }
	  }
  }
  .my-score{
	margin-bottom: -7px;
  }
  .user-exchange-list{
	.list(180px, 116px, 25px);
	>li{
	  padding-top: 22px;
	  padding-bottom: 22px;
	  border-bottom: 1px solid @border;
	//   cursor: pointer;
	  &:hover{
		  .content{
			  .btn-wrap{
				  span{
					  padding: 10px 0 10px 10px;
					cursor: pointer;
					  color: @red;
				  }
			  }
		  }
	  }
	  .img{
		border-radius: 4px;
	    background-color: @bg-color;
	  }
	  .content{
		flex-direction: row;
		align-items: center;
		.summary{
		  width: 315px;
		  padding-right: 15px;
		  .title{
			font-size: 18px;
			color: @black;
			margin-bottom: 10px;
			.text-overflow;
		  }
		  .title-desc-item{
			& + .title-desc-item{
			  margin-top: 4px;
			}
		  }
		}
		.status{
		  padding-left: 10px;
		  padding-right: 10px;
		  .flex-center;
		  height: 20px;
		  border-radius: 25px;
		  font-size: 12px;
		  background-color: #f0f2f5;
		  color: @blue;
		}
		.btn-wrap{
		  flex-grow: 1;
		  display: inline-flex;
		  justify-content: flex-end;
		  align-items: center;
		  span{
			color: @black;
			font-size: 14px;
		  }
		}
	  }
	}
  }
}

.exchange-detail-popup{
  .exchange-info-table{
	.price-name{
	  width: 250px;
	}
	.price-price{
	  width: 125px;
	}
	.price-num{
	  width: 125px;
	}
	.price-count{
	  width: 85px;
	}
  }
  	.title-desc-item{
		align-items: baseline;
		& + .title-desc-item{
			margin-top: 10px;
		}
		&.has-code {
			align-items: flex-start;
		}
		.title-desc-item__title{
			font-size: 16px;
		}
		.title-desc-item__desc{
			flex-grow: 1;
			font-size: 14px;
			&.qr-code-wrapper-gift {
				margin-bottom: 10px;
				flex-grow: 0;
				.flex;
				flex-direction: column;
				justify-content: center;
				padding: 8px 15px 30px 15px;
				box-sizing: border-box;
				width: 256px;
				height: 295px;
				border: solid 1px #ededed;
				.img {
					padding: 20px;
					box-sizing: content-box;
					.img-wrap(186px, 186px);
				}
				.btn-save-img-gift {
					background-color: transparent;
					text-align: center;
					font-size: 18px;
					color: #6483bb;
					cursor: pointer;
					&.gray {
						cursor: default;
						color: #666;
					}
				}
			}
		}
  	}
}

.page-user-score-list{
	.show-points-reason{
		cursor: pointer;
	}
  .user-score-list{
	>li{
	  display: flex;
	  align-items: center;
	  height: 65px;
	  border-bottom: 1px solid @border;
	  .title{
		font-size: 18px;
		color: @black;
		width: 210px;
		.text-overflow;
		padding-right: 15px;
	  }
	  .source{
		width: 270px;
		font-size: 14px;
		color: @light-black;
		.text-overflow;
		padding-right: 15px;
	  }
	  .time{
		width: 120px;
		font-size: 14px;
		color: @light-black;
	  }
	  .opera{
		color: @red;
		font-size: 14px;
		text-align: right;
		flex-grow: 1;
		.num{
		  font-size: 18px;
		}
	  }
	}
  }
}

.get-score-popup{
	.popup-info{
		// display: flex;
		// align-items: flex-start;
		.desc-content{
			// flex-grow: 1;
			padding-right: 30px;
		}
		.recommend-qr-code-wrapper{
			// border-left: 1px solid @border;
			text-align: center;
			width: 300px;
			flex-shrink: 0;
			.img{
				margin-bottom: 20px;
				.img-wrap(186px, 186px);
				display: flex;
			}
			.desc{
				font-size: 14px;
				color: @gray;
				padding-bottom: 10px;
			}
			.btn{
				font-size: 14px;
				height: 36px;
			}
			.recommend-qr-code {
				width: 186px;
			}
			.btn-qr-code-download {
				background-color: transparent;
				color: #6483bb;
				cursor: pointer;
				&.gray {
					cursor: default;
					color: #666;
				}
			}
		}
	}
}

.page-story-index{
	.story-list{
		>li{
			a {
				.img{
					img{
					}
				}
				.content{
					height: 166px;
					padding-top: 15px;
					.title{
						margin-bottom: 15px;
						line-height: 1.5;
						.text-limit2;
					}
					.time{
						margin-bottom: 17px;
					}
					.tag{
					}
				}
			}
		}
	}
}

.activity-issue-page{
	.top-menu{
		margin-bottom: 35px;
	}
	.form-desc-list {
		@width: 130px;

		.title {
			width: @width;
			height: 22px;
		}

		> .desc, .content .desc {
			margin-left: @width;
		}

		.desc {
		}

		.msg {
			margin-left: @width;
		}
	}

	.pay-item {
		img {
			height: 20px;
			width: auto;
			margin-right: 2px;
		}

		& + .pay-item {
			margin-left: 25px;
		}
	}

	.qs-table .qs-table-td{
		padding: 5px 0;
	}

	.form-desc-list > li > .desc, .form-desc-list > li .content > .desc{
		margin-left: 140px;
	}


	.btn-group{
		margin: 50px auto 30px;
		width: 400px;
		.btn{
			width: 180px;
			height: 40px;
		}
	}
}




.page-sx{
	.popup-wrapper .popup-header{
		border-bottom: 0;
		padding-bottom: 0;
		.popup-header-title,h3.title{
			margin-bottom: 0;
			span{
				border-bottom-color: @red;
			}
		}
	}

	//报名用户信息弹窗
	.popup-applicant-info {
		max-width: 820px;
		//&.popup-team-info{
		//  .popup-info{
		//    .user-info{
		//      .user{
		//        .avatar{
		//          float: left;
		//          .img-wrap(135px,95px);
		//          margin-right: 40px;
		//        }
		//      }
		//    }
		//  }
		//}
		.popup-info {
			width: 725px;

			.user-info {
				border-bottom: 1px solid @border;
				padding: 10px 0;
				overflow: hidden;

				.user, .info {
					height: 135px;
					float: left;
					display: table;
				}

				.user {
					border-right: 1px solid @border;
					overflow: hidden;
					align-items: center;
					color: @black;

					.avatar {
						float: left;
						.avatar(100px);
						margin-right: 40px;
					}

					.title {
						float: left;
						width: 220px;

						h3 {
							font-size: 16px;
							margin-bottom: 20px;
						}

						.date {
							font-size: 14px;
						}
					}
				}

				.info {
					padding-left: 45px;
					box-sizing: border-box;
					font-size: 14px;
					flex-direction: column;
					color: @black;
					justify-content: center;
					max-width: 319px;

					.in {
						margin-bottom: 10px;
					}

					.out {

					}
				}
			}

			.form-info {
				.extend {
					padding-top: 30px;

					.form-control {
						.form-field {
							label {
								height: 45px;
								line-height: 45px;
								display: block;

								input[type='checkbox'], input[type='radio'] {
									margin-right: 3px;
								}

								span {
								}
							}
						}
					}
				}

				.sub-title {
					font-size: 14px;
					color: @black;
					padding: 30px 0 25px;
				}

				.title-desc-list {
					margin-bottom: 30px;

					li {
						.title {
							float: left;
						}

						.desc {
							margin-left: 75px;
						}

						& + li {
							margin-top: 20px;
						}
					}
				}
			}
		}
		.btn-group{
			.btn{
				width: 140px;
			}
		}
	}

	.dis-table-cell{
		display: table-cell;
		vertical-align: middle;
	}

	&.project-submit{
		.btn-group{
			justify-content: center;
			.btn{
				flex-grow: 0;
				width: 190px;
				height: 50px;
			}
		}
	}

	.management-page{
		.r-main{
			.select-content-search{
				select,input{
					height: 35px;
				}
			}
		}
	}

	&.page-signin-list{
		.r-main {
			.activity-title {
				padding: 20px 0;
				font-size: 18px;

				.title {
					color: @gray;
				}

				.desc {
					color: @black;
				}
			}
			.sign-wrapper {
				margin-bottom: 25px;
				overflow: hidden;

				label {
					input {
						width: 17px;
						height: 17px;
					}

					span {
						margin-left: 5px;
					}
				}

				.btn {
					float: left;
					min-width: 75px;
					height: 35px;
					line-height: 35px;
					padding: 0 15px;

					& + .btn {
						margin-left: 25px;
					}
				}
			}
		}
	}

	&.page-sign-list {
		//签到列表
		.sx-sign-list {
		}

		.r-main {
			.activity-title {
				padding: 20px 0;
				font-size: 18px;

				.title {
					color: @gray;
				}

				.desc {
					color: @black;
				}
			}



			.sign-wrapper {
				margin-bottom: 25px;
				overflow: hidden;

				label {
					input {
						width: 17px;
						height: 17px;
					}

					span {
						margin-left: 5px;
					}
				}

				.btn {
					float: left;
					min-width: 75px;
					height: 35px;
					line-height: 35px;
					padding: 0 15px;

					& + .btn {
						margin-left: 25px;
					}
				}
			}
		}
	}
}


.sx-sign-item {
	height: 142px;
	&:first-child {
		border-top: 1px dashed @border;
	}
	position: relative;
	overflow: hidden;
	zoom: 1;
	font-size: 14px;
	padding: 20px 0;
	border-bottom: 1px dashed @border;
	input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 50%;
		width: 18px;
		height: 18px;
		transform: translateY(-50%);
	}
	.img {
		float: left;
		.avatar(100px);
		img {
			border-radius: 50%;
		}
	}
	.content {
		float: left;
		padding: 0;
		margin-left: 34px;
		width: 248px;
		display: table;
		.al-center {
			display: table-cell;
			vertical-align: middle;
			.title {
				margin-top: 20px;
				margin-bottom: 20px;
				display: inline-block;
				font-size: 16px;
				color: #333;
			}
			.desc {
				color: #999999;
				font-size: 14px;
			}
		}

	}
	.right-main {
		width: 350px;
		float: right;
		position: relative;
		height: 99px;
		.state {
			color: @orange;
			display: table;
			margin-bottom: 0;
			margin-right: 65px;
			height: 100%;
			div{
				display: table-cell;
				vertical-align: middle;
			}
		}
		.check-state{
			color:#1e81cd;
			float: left;
			line-height: 100px;
			height: 100px;
			margin-bottom: 0;
			margin-right: 65px;
		}
		.right-button {
			margin-top: 5px;
			width: 270px;
			transform: translateY(-50%);
			position: absolute;
			top: 50%;
			right: 0;
			margin-left: -5px;
			text-align: right;
			a {
				line-height: 26px;
				margin-left: 5px;
				height: 26px;
				border-radius: 13px;
				margin-bottom: 10px;
			}
		}
		&.signin-layout{
			width: 393px;
			& > .state{
				line-height: 100px;
			}
		}
	}

}


.exchange_statue_red {
	color: #e6212b;
  }
.exchange_status_orange {
	color: #ff763f;
}

