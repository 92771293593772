@import "./var";
@import "./tool";

.page{
  display: flex;
  flex-direction: column;
  header,footer{
    flex-shrink: 0;
  }
  main{
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
  }
}
.text-cut {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.justify-between {
	justify-content: space-between;
}
.text-red{
  color: @red !important;
}

.text-orange{
  color: @orange !important;
}

.text-blue{
  color: @blue !important;
}

.text-light-black{
  color: @light-black !important;
}

.text-black{
  color: @black !important;
}

.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  background-color: @red;
  color: #fff;
  padding: 0 20px;

  .btn-color(@bg-color,@border-color: @bg-color,@color: @bg-color){
    background: @bg-color;
    color: #fff;
    border: 1px solid @border-color;
    &.btn-border{
      color: @color;
      background: #fff;
      border: 1px solid @color;
      &:hover{
        background-color: @border-color;
        color: #fff;
      }
    }
  }

  &.btn-red{
    background: url('../../../../img/mobile/bg-btn.png') no-repeat center;
    background-size: 100% 100%;
    &.btn-shadow{
      box-shadow: 0px 2px 12px 0px rgba(217, 12, 21, 0.36);
    }
    &.btn-border{
      background-color: #fff;
      color: @red;
      border: 1px solid @red;
    }
  }

  &.btn-red-border{
    background-color: transparent;
    border: 1px solid @red;
    color: @red;
  }

  &.btn-light-red{
    background: url('../../../../img/mobile/bg-btn-red.jpg') no-repeat center;
    background-size: 100% 100%;
  }

  &.btn-dark-red {
    background-color: @dark-red;
    color: #fff;
    &.btn-shadow{
      box-shadow: 0px 2px 12px 0px rgba(217, 12, 21, 0.36);
    }
    &.btn-border{
      background-color: #fff;
      color: @dark-red;
      border: 1px solid @dark-red;
    }
  }
  &.btn-orange {
    background-color: @orange;
    color: #fff;
    &.btn-shadow{
      box-shadow: 0px 2px 12px 0px rgba(217, 12, 21, 0.36);
    }
  }

  &.btn-gray{
    .btn-color(#b9babd);
  }

  &.btn-square{
    border-radius: 0 !important;
  }

  &.btn-radius{
    border-radius: 25px !important;
  }

  &.btn-block{
    display: flex;
    width: 100%;
  }

  &[disabled],&.btn-disabled{
    opacity: .6;
    pointer-events: none;
  }
}

.process-wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__process{
    flex-grow: 1;
    height: 8px;
    background-color: #f5f5f5;
    border-radius: 2px;
    &-bar{
      border-radius: 2px;
      position: relative;
      height: 100%;
      width: 0;
      transition: all 3s;
      background-image: linear-gradient(to right, #ff773f, @red);
      &-point{
        position: absolute;
        right: 0;
        top: 0;
        margin-right: -2px;
        margin-top: -2px;
        width: 12px;
        height: 12px;
        background-color: @red;
        border-radius: 50%;
      }
    }
  }
  &__text{
    font-size: 14px;
    color: @gray;
    margin-left: 15px;
  }
}


input,select, textarea{
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #ededed;
  color: @light-black;
}

textarea{
  border-radius: 4px;
  resize: none;
  width: 100%;
  font-size: 12px;
}

body{
  .layui-laydate .layui-this{
    background-color: @blue !important;
  }
  .layui-laydate-footer span:hover{
    color: @blue !important;
  }
}

.tag{
  border-radius: 4px 0 25px 0;
  background-color: @red;
  color: #fff;
  &.tag-orange{
    background-color: #ff763f;
    &.tag-reverse{
      color: #ff763f;
    }
  }
  &.tag-gray{
    background-color: #b8b1ae;
    &.tag-reverse{
      color: #666;
    }
  }
  &.tag-reverse{
    background-color: #fff;
    color: @red;
  }
}

.raise-list{
  display: flex;
  align-items: center;
  >li{
    flex-shrink: 0;
    flex-grow: 1;
    .raise-list-title{
      color: @gray;
    }
    .raise-list-count{
      color: @red;
    }
  }
}

div.msgBoxBackGround {
  top: 0;
  left: 0;
  position: absolute;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.6;
  z-index: 1000;
}
div.msgBox {
  box-sizing: border-box;
  padding: 15px;
  position: fixed;
  z-index: 1050;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 11px rgba(0, 0, 0, 0.3);
  .msgBoxTitle {
    width: 100%;
    border-bottom: 1px solid #eaeaea;
    color: #333;
    font-size: 18px;
  }
  .msgBoxImage {
    display: none;
  }
  .msgBoxContent {
    color: #333;
    font-size: 14px;
    line-height: 1.6;
    max-height: 500px;
    overflow-y: auto;
    span{
      color: #333;
    }
  }

  div.msgBoxButtons {
    display: flex;
    justify-content: space-around;
  }
}

.image-source-link {
  color: #98C3D1;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

body.over-hidden{
  overflow: unset !important;
}

.user-meta{
  display: flex;
  align-items: center;
  .avatar{
    .avatar(1.2rem);
    margin-right: .25rem;
  }
  .user-meta-info{
    .user-name{
      font-size: .32rem;
      color: @black;
      margin-bottom: .1rem;
      line-height: 1.5;
    }
    .user-desc{
      font-size: .28rem;
      line-height: 1.5;
      color: @gray;
    }
  }
}

.summary-list {
  > li {

    .summary-list__title {
      color: @light-black;
    }

    .summary-list__desc {
      color: @red;
    }

    & + li {
    }
  }

  &.summary-list-reverse {
    > li {

      .summary-list__title {
      }

      .summary-list__desc {
      }
    }
  }
}

.user-info{
  .avatar{
    float: left;
  }
  .meta-content{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-grow: 1;
    .meta-content-top{
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      .user-name{
        color: @black;
      }
      .donate-count{
        color: @light-black;
        .donate-count-desc{
          display: inline;
        }
      }
      .msg-type{
        color: @gray;
      }
    }
    .meta-content-desc{
      line-height: 1.5;
      color: @light-black;
    }
  }
}

.msg-list{
  >li{
    box-sizing: content-box;
    border-bottom: 1px solid @border;
    .content{
      .desc{
        background-color: #f5f7fa;
        border-radius: 4px;
        color: @black;
        line-height: 1.5;
        position: relative;
        display: flex;
        align-items: flex-start;
        .person-name{
          flex-shrink: 0;
        }
        &:before{
          content: '';
          .triangle-bottom;
          border-bottom: 13px solid #f5f7fa;
          border-top: 8px solid transparent;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          position: absolute;
          left: .2rem;
          top: -18px;
        }
      }
      >.time{
      }
      .time{
        color: @gray;
      }
      .support-wish{
        color: @blue;
      }
      .bottom{
        display: flex;
        align-items: center;
        .oprea{
          color: @blue;
        }
      }
    }
  }
}

.img-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -.2rem;
  margin-left: -.25rem;
  &.auto-size{
    >li{
      width: auto;
      height: auto;
    }
  }
  >li{
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: .2rem;
    margin-left: .25rem;
    .img-wrap(calc((100% - .25rem * 3) / 3), 1.25rem);
  }
}

.tooltipster-sidetip.tooltipster-borderless .tooltipster-box{
  background-color: @orange !important;
}

.tooltipster-sidetip.tooltipster-borderless.tooltipster-top .tooltipster-arrow-border{
  border-top-color: @orange !important;
}

.vjs-time-tooltip,.vjs-mouse-display{display: none!important;}

.num-toggle-render{
  height: .6rem;
  display: flex;
  align-items: center;
  .current-number-wrapper{
    text-align: center;
    margin-left: 2px;
    margin-right: 2px;
    input{
      height: .6rem;
      width: 1rem;
      text-align: center;
      border-radius: 0;
      padding-left: .1rem;
      padding-right: .1rem;
      border: 1px solid #dbdbdb;
      background-color: #fff;
    }
  }
  .add,.subtract{
    border: 1px solid #dbdbdb;
    width: .5rem;
    height: .6rem;
    .flex-center;
    cursor: pointer;
  }
}

