@import "var";

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  word-break: break-all;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1.2;
}
ol, ul,li {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
body{
  font: 16px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
  color: #333;
  background-color: #fff;
}
a{
  text-decoration: none;
  cursor: pointer;
}
p{
  line-height: 1.5;
}

img{
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle;
}

/* 普通文章样式 */
.article-content{
  padding-top: 10px;
  overflow: hidden;
  table
  {
    border-collapse:collapse;
    width: auto;
    word-break: break-all;
    max-width: 100%;
    table-layout: fixed;
    td,th{
      word-break: break-all;
      border: 1px solid @border;
      width: auto;
      padding: 5px 10px;
      vertical-align: middle;
    }
  }

  iframe{
    width: 100%;
  }
  span{
    display: inline;
  }
  ol,ul,li{
    list-style: inherit;
  }

  ul {
    margin-left: 1em;
    padding: 10px 0;
  }
  ol {
    margin-left: 1.5em;
    padding: 10px 0;
  }
  .article-content a {
    color: #0b76ac;
    text-decoration: underline;
  }
}
.article-content img {
  max-width: 100%!important;
  height: auto!important;
}
.article-content p,.article-content pre {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
}
.article-content p:last-child{
  margin-bottom: 0;
}
.article-content h1 {
  font-size: 20px;
}
.article-content h2 {
  font-size: 18px;
}
.article-content h3 {
  font-size: 16px;
}
.article-content h4,
.article-content h5,
.article-content h6 {
  font-size: 14px;
}
.article-content h1,
.article-content h2,
.article-content h3,
.article-content h4,
.article-content h5,
.article-content h6 {
  padding: 15px 0;
  font-weight: bold;
}
.article-content ul {
  list-style: square;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content a {
  color: #0b76ac;
  text-decoration: underline;
}
.article-content ol {
  list-style: decimal;
  margin-left: 1em;
  padding: 10px 0;
}
.article-content blockquote {
  padding-left: 10px;
  border-left: 3px solid #dbdbdb;
  font-size: 14px;
}
.article-content blockquote p {
  font-size: 14px;
}

* {
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

html{
  min-height: 100%;
  height: 100%;
  body{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .page{
      flex-grow: 1;
    }
  }
}

input,button,textarea,select{
  outline: none;
  border: 0;
  &:focus{
    outline: none;
  }
}
a{
  color: unset;
}
button{
  border: 0;
}

pre[wrap]{
  word-break: break-all;
}
