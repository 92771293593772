@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.page-user-involved-activity-list{
  .top-menu{
    margin-bottom: 17px;
    .top-menu-right{
      .show-points-reason{
        cursor: pointer;
      }
    }
  }
  .user-involved-activity-list{
    .list(134px, 94px, 20px);
    >li{
      padding-top: 22px;
      padding-bottom: 22px;
      border-bottom: 1px solid @border;
      cursor: pointer;
      &:hover{
        .content{
          .summary{
            .title{
              color: @red;
            }
          }
        }
        .img{
          img{
            transform: scale(1.05);
            transition: all .3s;
          }
        }
      }
      .img{
        border-radius: 4px;
      }
      .activity-info {
        width: 315px;
        padding-right: 15px;
        flex-direction: column;
        .title {
          font-size: 16px;
          color: @black;
          line-height: 26px;
          margin-bottom: 25px;
          .text-limit2;
        }
        .time {
          font-size: 14px;
          color: @gray;
          .tag-orange{
            font-size: 12px;
            background-color: @orange;
            color: @white;
          }
        }
      }
      .right-side{
        height: 94px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        flex-shrink: 0;
        .status-text{
          float: right;
          font-size: 14px;
          color: @orange;
        }
        .operation{
          margin-top: 50px;
          display: inline-flex;
          gap: 10px;
          float: right;
          .btn{
            width: 110px;
            height: 26px;
            border-radius: 13px;
            font-size: 14px;
            padding: 0px 10px;
          }
        }
      }
    }
  }
}

.user-involved-activity-popup{
  padding: 0;
  width: 1060px;
  //max-height: 377px;
  .popup-content{
    padding: 0;
    margin-bottom: 0;
  }
  .popup-header{
    padding: 10px 30px 30px 30px;
    border-bottom: none;
    .title{
      font-size: 18px;
      color: @black;
      border-bottom:none;
      .text-overflow
    }
  }
  .content{
    border-top: 1px dashed @border-color;
    display: flex;
    justify-content: space-between;
    .left-content{
      height: 290px;
      border-right: 1px dashed @border-color;
      .left-info{
        margin: 40px 40px 0px 40px;
        .img{
          margin-bottom: 30px;
          .img-wrap(223px, 120px);
        }
        .title{
          .flex-center;
          font-size: 14px;
          color: @black;
          margin-bottom: 10px;
        }
        .code{
          .flex-center;
          font-size: 26px;
          color: @orange;
        }
      }

    }
    .right-content{
      padding-right: 20px;
      float: left;
      position: relative;
      .icon-sign-code-invalid{
        position: absolute;
        top: 70px;
        right: 20px;
      }
      .right-info{
        padding-left: 20px;
        .title-desc-list{
          padding-top: 30px;
          border-bottom: 1px dashed @border-color;
          >li{
            margin-bottom: 10px;
            .title{
              font-size: 14px;
              color: @gray;
            }
            .desc{
              font-size: 14px;
              color: @black;
            }
          }
        }
        .activity-info-list{

        }
        .user-info-list{
          padding-bottom: 10px;
          >li{
            display: inline;
            margin-right: 70px;
          }
        }
        .tips-wrapper{
          padding-top: 30px;
          .title{
            padding-bottom: 5px;
            font-size: 14px;
            color: @gray;
          }
          .tips-content{
            font-size: 14px;
            color: @black;
          }
        }
      }
    }
  }
}

.user-involved-activity-cancel{
  .popup-header{
    border-bottom: none;
    h3.title{
      margin-bottom: 0;
    }
  }

  .popup-content{
    .popup-info h4{
      font-size: 18px;
    }
    .form-group{
      margin-top: 20px;
    }
  }
}