@import '../../../commonStyle/reset';
@import '../../../commonStyle/common';
@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';
@import './icon.less';
@import './common';
@import './tool';
@import './wj';
@import './zj';
@import './activity';
@import './xh';

body {
  display: flex;
  flex-flow: column;
  min-height: 100vh;
}
main {
  flex: 1;
  padding-top: @header_height;
  display: flex;
  flex-direction: column;
}
footer {
  background-color: @red;
  padding: 42px 0 12px 0;
  min-width: @container_width;
  position: relative;
  height: 186px;
  &:before{
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 887px;
    height: 186px;
    .bg(bg-footer-left, png, center);
  }
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 610px;
    height: 186px;
    .bg(bg-footer-right, png, center);
  }
  .footer-bg {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: flex-start;
    color: #fff;
    a{
      color: #fff;
    }
    .footer-left-message {
      padding-right: 45px;
      position: relative;
      flex: 1;
      // border-right: 1px solid #dbdbdb;
      .footer-l-t {
        font-size: 12px;
        width: 100%;
        .contact-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 19px;
          .contact-item {
            display: flex;
            align-items: center;
            .field {
              line-height: 1.2;
              flex-shrink: 0;
            }
            .message {
              max-width: 300px;
              line-height: 1.2;
            }
          }
        }
        .unit-box {
          margin-bottom: 33px;
          .unit-item {
            margin-right: 30px;
          }
        }
      }
      .footer-l-b {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        font-size: 12px;
        padding-top: 20px;
        border-top: 1px solid rgba(225, 38, 45, .5);
      }
    }

    .right-qrcode {
      margin-top: 2px;
      flex-shrink: 0;
      margin-left: 40px;
      width: 300px;
      display: flex;
      justify-content: space-between;
      .qrcode-item {
        text-align: center;
        .qrcode-item-wrapper{
          .img-wrap(74px, 74px);
          margin-bottom: 8px;
          padding: 4px;
          background-color: #fff;
        }
        .text {
          font-size: 10px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

header {
  padding: 18px 0 0 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: @header_height;
  .bg(bg-header, jpg);
  background-color: @red;
  box-shadow: 0 2px 2px rgba(140, 140, 140, .3);
  &:after{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 460px;
    height: 74px;
    .bg(bg-header-starts, png, right top, cover);
  }
  .header-bg {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    > .logo {
      > img {
        height: 65px;
      }
    }
    > .right-menu {
      .right-menu__top{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 15px;
        .btn {
          height: 24px;
          padding-left: 8px;
          padding-right: 8px;
          margin-left: 12px;
          background-color: #ea3522;
        }
      }
      .nav-list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 16px;
        >li{
          position: relative;
          &:after{
            content: '';
            height: 40px;
            position: absolute;
            left: 0;
            top: 17px;
            width: 100%;
            background-color: transparent;
          }
          &:before{
            content: '';
            position: absolute;
            left: 50%;
            bottom: -8px;
            margin-left: -8px;
            width: 16px;
            height: 2px;
            background-color: #fff;
            transform: scaleX(0);
            transition: all .3s;
          }
          & + li{
            margin-left: 25px;
          }
          >a{
            color: #fff;
            padding-left: 5px;
          }
          &.active,&.now{
            &:before{
              transform: scaleX(1);
            }
          }
          .submenu{
            font-size: 14px;
            display: none;
            position: absolute;
            left: 0;
            top: 40px;
            width: 180px;
            background-color: #fff;
            border-bottom: 4px solid @red;
            box-shadow: 0 2px 8px 0 rgba(2,30,7,.1);
            >li{
              a{
                height: 45px;
                line-height: 45px;
                padding-left: 25px;
                display: block;
                color: @light-black;
              }
              &:hover, &.active{
                a{
                  color: @red;
                }
              }
            }
          }
        }
      }
    }
  }
}

.login-box {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-menu {
    background-color: #fff;
    width: 520px;
    height: auto;
    box-shadow: 0px 6px 20px 0px rgba(71, 6, 5, 0.08);
    .tab-item-menu {
      padding: 20px 0;
      display: flex;
      border-bottom: 1px solid @border;
      .tab-item {
        cursor: pointer;
        flex: 1;
        text-align: center;
        font-size: 18px;
        color: @black;
        &.active {
          color: @red;
        }
        &:hover {
          color: @red;
        }
      }
    }
    .qr-code-box {
      width: 100%;
      text-align: center;
      .flex-center;
      .user-code {
        padding: 58px 0 35px 0;
        > img {
          width: 186px;
          height: 186px;
          display: block;
        }
      }
    }
    .tips-text {
      color: @gray;
      font-size: 14px;
      text-align: center;
      width: 100%;
      margin: 0 auto;
      margin-top: 36px;
    }
    .register {
      font-size: 14px;
      color: @red;
      margin-top: 70px;
      text-align: center;
      display: inline-block;
    }
    .org-code {
      padding: 70px 0 18px 0;
      width: 397px;
      .find-password {
        display: inline-block;
        margin-top: 12px;
        color: @black;
        font-size: 14px;
        width: 100%;
        text-align: right;
      }
      .btn-submit {
        margin-top: 32px;
        height: 40px;
      }
    }
  }
  .qs-form{
    .input-default{
      > label{
        flex: 0 0 42px;
      }
    }
  }
}
//获取验证码
.get-verification {
  width: 84px;
  height: 100%;
  .flex-center;
  background-color: @orange;
  color: #fff;
  border-radius: 0px 6px 6px 0px;
  font-size: 14px;
}
.register-box {
  // padding-top: 54px;
  .flex-center;
  .form {
    width: 520px;
    background-color: #ffffff;
    box-shadow: 0px 6px 20px 0px rgba(71, 6, 5, 0.08);
    > .title {
      height: 66px;
      border-bottom: 1px solid @border;
      text-align: center;
      h2 {
        font-weight: bold;
        line-height: 66px;
        font-size: 18px;
        color: @black;
      }
    }
    .input-default {
      margin-bottom: 20px;
      > label {
        flex: 0 0 84px;
        text-align: right;
      }
      > input {
      }
    }
    .form-warp {
      padding: 0 48px;
      padding-top: 40px;
      padding-bottom: 90px;
    }
    .btn-submit {
      margin-top: 41px;
      height: 40px;
      font-size: 14px;
    }
  }
}
.findPassword-box {
  .form {
    .form-warp {
      padding-top: 0;
      .input-default {
        label {
          flex: 0 0 84px;
        }
      }
    }
  }
}

.sidebar-box {
  padding: 0;
  background-color: #ffffff;
  width: 210px;
  margin-bottom: 94px;
  .sidebar-title {
    padding: 15px 30px 15px 30px;
    font-size: 18px;
    color: @white;
    font-weight: bold;
    border-bottom: 1px solid @border;
    width: 100%;
    background-image: url(../../../../img/pc/sidebar-title-bg.jpg);
    .flex-center
  }
  .sidebar-list {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    .sidebar-item {
      width: 100%;
      margin-top: 16px;
      padding: 10px 30px 10px 30px;
      &:last-child{
        >a{
          >img{
            display: none;
          }
        }
      }
      > a {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        color: @black;
        font-size: 16px;
        > img {
          width: 6px;
          height: 11px;
          display: block;
        }
      }
      &.active {
        padding-left: 25px;
        border-left: 5px @dark-red solid;
        background-color: #fff3f3;
        > a {
          color: @red;
        }
      }
      &:hover {
        > a {
          color: @red;
        }
      }
      &:first-child {
        margin-top: 26px;
      }
    }
  }
}
.right-content-wrap {
  width: 840px;
  .top-message-box {
    margin-bottom: 20px;
    height: 110px;
    width: 100%;
    display: flex;
    background-color: #fff;
    &.user-msg-box {
      .left-img-wrap {
        .bg(bg-user-avatar, jpg);
        .img-wrapper {
          width: 90px;
          height: 90px;
          background-color: #fff;
          padding: 2px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          .img {
            .avatar(86px);
          }
        }
      }
      .info {
        .btn {
          font-size: 14px;
          color: #fff;
          height: 30px;
          padding: 0 10px;
        }
      }
    }
    .left-img-wrap {
      width: 156px;
      height: 100%;
      background-color: #fff;
      box-shadow: 0px 2px 6px 0px rgba(71, 6, 5, 0.2);
      transform: scale(1.1);
      transform-origin: left center;
      .flex-center;
      img {
        width: 86px;
        height: 86px;
      }
    }
    .right-wrap {
      padding: 18px 0;
      margin: 0 20px 0 34px;
      flex: 1;
      .info {
        margin-bottom: 14px;
        padding-bottom: 14px;
        border-bottom: 1px solid @border;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          font-size: 18px;
          color: @black;
        }
        > .volun-tag{
          width: 70px;
          height: 22px;
          line-height: 22px;
          padding: 0;
          font-size: 12px;
          color: @red;

        }
        > .org-name{
          font-size: 14px;
          color: @gray;
        }
        &.j-c-start{
          justify-content: flex-start;
          > .volun-tag{
            margin: 0 14px;
          }
        }
      }
      .bottom-box {
        .flex;
        justify-content: space-between;
        > .item {
          color: @black;
          font-size: 14px;
          flex: 1;
          > .text-red {
            font-weight: bold;
          }
        }
      }
    }
  }
  .user-meta-box{
    padding: 10px 20px 15px;
    background-color: #ffffff;
    margin-bottom: 20px;
    .user-meta-box__top{
      display: flex;
      align-items: center;
      padding-bottom: 10px;
      border-bottom: 1px solid @border;
      margin-bottom: 15px;
      .img{
        .avatar(88px);
        padding: 2px;
        box-shadow: 0px 2px 12px 0px rgba(19, 1, 1, 0.26);
        margin-right: 20px;
      }
      .user-meta-box__top__content{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        .user-info-box{
          width: 455px;
          .user-info{
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            .user-name{
              font-size: 18px;
              color: @black;
              margin-right: 10px;
            }
            .tag-list{
              display: flex;
              align-items: center;
              .user-tag{
                .flex-center;
                border-radius: 4px;
                color: @red;
                background-color: #ffdbce;
                font-size: 12px;
                padding-left: 4px;
                padding-right: 4px;
                height: 22px;
                & + .user-tag{
                  margin-left: 6px;
                }
              }
            }
          }
          .user-info-data{
            display: flex;
            align-items: center;
            .level{
              padding: 0 6px;
              height: 16px;
              border-radius: 4px;
              font-size: 12px;
              .flex-center;
              color: #fff;
              background-color: #f7aa36;
              margin-right: 12px;
            }
            .user-info-data__item{
              font-size: 14px;
              color: @gray;
              & + .user-info-data__item{
                margin-left: 40px;
              }
            }
            .record-reason{
              margin-left: 13px;
              color: @blue;
              font-size: 14px;
            }
          }
        }
        .process-wrapper{
          margin-bottom: 5px;
          .process-wrapper__process{
            height: 6px;
            .process-wrapper__process-bar{
              .process-wrapper__process-bar-point{
                margin-top: -3px;
              }
            }
          }
        }
        .user-info-btns{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          .btn{
            width: 100px;
            height: 27px;
            font-size: 14px;
            padding-left: 5px;
            padding-right: 5px;
            & + .btn{
              margin-top: 10px;
            }
          }
        }
      }
    }
    .user-meta-box__bottom{
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
    }
  }
}

.top-menu {
  border-bottom: 1px solid @border;
  display: flex;
  justify-content: space-between;
  > .title{
    font-size: 18px;
    color: #19191a;
    padding-bottom: 9px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      bottom: -3px;
      left: 0;
      width: 100%;
      height: 3px;
      background-image: linear-gradient( 90deg, rgb(241,109,37) 0%, rgb(237,194,20) 0%, rgb(255,119,63) 0%, rgb(255,13,25) 82%);
    }
  }
  &__left{
    justify-content: flex-start;
    .tab-menu{
      & + .tab-menu{
        margin-left: 135px;
      }
    }
  }
  .tab-menu{
    display: flex;
    > .title {
      font-size: 18px;
      color: #19191a;
      padding-bottom: 11px;
      position: relative;
      &.active{
        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 3px;
          background-image: linear-gradient( 90deg, rgb(241,109,37) 0%, rgb(237,194,20) 0%, rgb(255,119,63) 0%, rgb(255,13,25) 82%);
        }
      }
      & + .title{
        margin-left: 70px;
      }
    }
  }
  .top-menu-right {
    display: flex;
    align-items: center;
    margin-top: -10px;
    margin-bottom: 10px;
    .icon-desc-item{
      margin-right: 20px;
      .icon-desc-item__desc{
        color: @gray;
        font-size: 14px;
      }
    }
    .btn{
      & + .btn{
        margin-left: 10px;
      }
    }
    .to-back {
      font-size: 14px;
      color: @gray;
      margin-left: 20px;
      // margin-top: 8px;
      &:hover {
        color: @red;
      }
      &.mt-4{
        margin-top: 4px;
      }
    }
    > a.btn{
      height: 30px;
    }
    &-btn {
      position: relative;
      > a {
        width: 88px;
        height: 30px;
        padding: 0;
        font-size: 14px;
      }
      > span {
        position: absolute;
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0px rgba(71, 6, 5, 0.08);
        border: solid 1px @bg-color;
        font-size: 14px;
        color: @red;
        display: inline-block;
        right: -8px;
        text-align: center;
        height: 21px;
        border-radius: 50%;
        top: -14px;
        width: 21px;
      }
    }
    .add-progress {
      width: 88px;
      height: 30px;
      padding: 0;
      font-size: 14px;
    }
    .to-more {
      font-size: 14px;
      color: @gray;
      &:hover {
        color: @red;
      }
    }
    select{
      height: 36px;
    }
  }
}

.r-main {
  padding: 20px;
  background-color: #ffffff;
  margin-bottom: 94px;
  &.bottom-space {
    padding-bottom: 80px;
  }
  .qs-wish-list {
    .qs-wish-list-item {
      padding: 22px 0;
      border-bottom: 1px solid @border;
      display: flex;
      > .img {
        img{
          border: 1px solid @border;
          width: 180px;
          height: 116px;
          border-radius: 6px;
          display: block;
          margin-right: 24px;
        }
      }
      > .item-r-content {
        flex: 1;
        .t-and-s {
          margin-bottom: 6px;
          display: flex;
          justify-content: space-between;
          .title {
            font-size: 18px;
            color: @black;
            .text-overflow;
            width: 500px;
            max-width: 500px;
          }
          .status {
            font-size: 12px;
            color: @blue;
            background-color: #f0f2f5;
            border-radius: 10px;
            height: 20px;
            padding: 0 8px;
            .flex-center;
          }
        }
        .goods-message {
          font-size: 14px;
          height: 83px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .initiator {
            color: @gray;
            margin-bottom: 8px;
            > span {
              color: @light-black;
            }
          }
          .fund-box {
            margin-bottom: 14px;
            .fund-item {
              color: @gray;
              margin-right: 70px;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .bottom-message {
            display: flex;
            justify-content: space-between;
            position: relative;
            .date {
              font-size: 14px;
              color: @gray;
            }
            .operation-menu {
              position: absolute;
              right: 0;
              bottom: 0;
              > .top{
                display: flex;
                justify-content: flex-end;
                margin-bottom: 12px;
              }
              .menu-item {
                display: inline-block;
                padding-right: 12px;
                border-right: 1px solid @border;
                margin-right: 12px;
                font-size: 14px;
                color: @black;
                position: relative;
                &:last-child {
                  margin-right: 0;
                  padding-right: 0;
                  border-right: none;
                }
                &:hover {
                  color: @red;
                }
                &.new {
                  position: relative;
                  &:after {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: -16px;
                    right: 0;
                    background-image: url(../../../../img/pc/new.png);
                    width: 24px;
                    height: 19px;
                  }
                }
                .num{
                  position: absolute;
                  top: -15px;
                  right: 0px;
                  border-radius: 50%;
                  height: 20px;
                  width: 20px;
                  background-color: @red;
                  color: #fff;
                  line-height: 20px;
                  text-align: center;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
    &.has-b {
      padding-bottom: 56px;
    }
  }
}
.management-page {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 24px;
}
.org-data-box {
  .form {
    width: 664px;
    .form-warp {
      margin-top: 48px;
      > .not-input-default {
        .label-title {
          flex: 0 0 94px;
          text-align: right;
        }
      }
      > .input-default {
        margin-bottom: 20px;
        label {
          flex: 0 0 94px;
          text-align: right;
        }
      }
      .verification-input {
        > input {
          flex: 0 0 300px;
        }
        > .get-verification {
          right: 260px;
        }
      }
      .img-verification-input{
        .get-img-verification{
          position: absolute;
          top: 0;
          right: 260px;
        }
      }
    }
    button[type='submit'] {
      height: 36px;
    }
  }
}
.org-wish-List-page {
  .qs-form {
    > .input-default {
      margin-left: 10px;
      margin-bottom: 7px;
      > input {
        width: 210px;
        height: 36px;
        border: 1px solid @border;
      }
      > .search-icon {
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
        background-image: none;
        padding: 0 6px;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
      }
    }
    .select-default {
      border: 1px solid @border;
      padding: 0px 10px;
    }
  }
}
//列表页码
.pagination-box {
  color: @gray;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  .pagination-btn {
    width: 64px;
    height: 24px;
    color: @gray;
    border-radius: 4px;
    border: solid 1px @border;
    font-size: 14px;
  }
  .prev,.next{
    &.disabled{
      pointer-events: none;
    }
  }
  .prev {
    margin-right: 18px;
  }
  .next {
    margin-left: 18px;
    margin-right: 8px;
  }
  .page-count {
    font-size: 14px;
    > .input {
      width: 34px;
      height: 24px;
      border-radius: 4px;
      border: solid 1px @border;
      padding: 0 4px;
      margin-right: 6px;
      margin-left: 9px;
    }
    > .input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    > .input[type='number'] {
      -moz-appearance: textfield;
    }
    > .confirm {
      margin-left: 12px;
      color: @red;
      font-size: 14px;
      display: inline-block;
      padding: 0 8px;
      border-radius: 4px;
      border: 1px solid @border;
    }
  }
  .n-list {
    .n {
      color: @gray;
      font-size: 16px;
      margin-right: 14px;
      &:last-child {
        margin-right: 0;
      }
      &.current {
        color: @red;
        pointer-events: none;
      }
      &:hover {
        color: @red;
      }
    }
  }
}

.qs-form .ossuploader-add,
.qs-form .ossuploader-dash-border {
  border-radius: 6px;
  border: solid 1px #dbdbdb;
  .flex-center;
  height: 120px;
  > i {
    //display: none;
  }
  > p {
    font-size: 14px;
    color: @blue;
    margin-top: 0;
    cursor: pointer;
  }
}
.ossuploader-upload-box{
  flex-grow: 1;
}
.qs-form.oss-mb0 .ossuploader-add,
.qs-form.oss-mb0 .ossuploader-dash-border{
  margin-bottom: 0;
}
.qs-form.round-box .ossuploader-add,
.qs-form.round-box .ossuploader-dash-border {
  border-radius: 50%;
  overflow: hidden;
  width: 120px;
  position: relative;
}

.section-line {
  margin-top: 29px;
  margin-bottom: 23px;
  height: 1px;
  width: 100%;
  background-color: @border;
}
.org-audit-wish-page {
  .form {
    margin-top: 34px;
    .align-left-message {
      text-align: left;
      .label-title {
        flex: 0 0 100px;
      }
      .btn {
        width: 110px;
        height: 28px;
        font-size: 14px;
      }
    }
    .tips-text {
      font-size: 14px;
      color: @gray;
      position: absolute;
      left: 110px;
      bottom: -15px;
    }
    .not-input-default {
      margin-bottom: 14px;
      .label-title {
        flex: 0 0 100px;
        color: @black;
        &.algin-l {
          text-align: left;
        }
        &.algin-r {
          text-align: right;
        }
        &.f-16 {
          font-size: 16px;
        }
      }
    }
    .input-default {
      margin-bottom: 20px;
      label {
        text-align: right;
        flex: 0 0 100px;
        &.algin-l {
          text-align: left;
        }
      }
      textarea {
        height: 84px;
        padding: 13px;
        line-height: 1.6;
      }
      .writable {
        color: @black;
      }
    }
    &.not-margin {
      margin: 0;
    }
  }
  &.form-mb-30 {
    .input-default {
      margin-bottom: 30px;
    }
    .not-input-default {
      margin-bottom: 23px;
    }
  }
}
.operaSuccess-page {
  margin-top: 135px;
  width: 840px;
  height: 500px;
  background-color: #fff;
  justify-content: center;
  margin: 0 auto;
  > .result {
    font-size: 18px;
    color: @black;
    margin-top: 36px;
    margin-bottom: 24px;
    font-weight: bold;
    text-align: center;
    max-width: 80%;
    line-height: 1.5;
  }
  > .result-tips {
    font-size: 14px;
    color: @light-black;
    margin-bottom: 46px;
  }
  .success-btn {
    min-width: 100px;
    height: 28px;
    padding: 0 8px;
    font-size: 14px;
  }
}
.wish-title-line {
  color: @blue;
  font-size: 16px;
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  .l {
    flex: 0 0 64px;
  }
}
.d-record-table {
  .qs-table-th,
  .qs-table-td {
    text-align: left;
  }
  .one {
    width: 197px;
    padding-right: 10px;
  }
  .two {
    width: 157px;
  }
  .three {
    width: 173px;
  }
  .four {
    width: 173px;
  }
}
.d-application-table {
  .qs-table-th,
  .qs-table-td {
    text-align: left;
  }
  .one {
    width: 166px;
    padding-right: 10px;
  }
  .two {
    width: 170px;
  }
  .three {
    width: 186px;
  }
  .four {
    width: 171px;
  }
  .text-blue {
    color: @blue;
  }
}
.operation-view {
  display: flex;
  font-size: 14px;
  color: @black;
  justify-content: flex-start;
  > .item {
    padding-right: 10px;
    border-right: 1px solid @border;
    margin-right: 10px;
    line-height: 1;
    &:last-child {
      padding-right: 0;
      border-right: none;
      margin-right: 0px;
    }
    &:hover {
      color: @red;
    }
  }
}
.org-donate-detail-page {
  .qs-form {
    margin-top: 41px;
    .not-input-default,
    .input-default {
      margin-bottom: 23px;
      label,
      .label-title {
        flex: 0 0 100px;
        text-align: right;
      }
    }
    .img-list {
      display: flex;
      flex-wrap: wrap;
      flex: 0 0 615px;
      > img {
        width: 31.38%;
        height: 126px;
        margin-bottom: 16px;
      }
      > img:not(:nth-child(3n)) {
        margin-right: calc(5.8% / 2);
      }
    }
    .btn-input {
      margin-top: 34px;
      .btn {
        width: 130px;
        height: 36px;
      }
    }
  }
}
.confirm-popup {
  .qs-popup-content {
    .qs-title {
      margin-bottom: 20px;
    }
    .text {
      max-height: 300px;
      overflow: auto;
    }
    &.popup-form {
      width: 100%;
      .qs-title {
        margin-bottom: 10px;
      }
      .cause-box {
        > .title {
          font-size: 14px;
          color: @black;
          margin-bottom: 10px;
          text-align: left;
        }
        .select-input {
          padding: 0 13px;
          width: 100%;
          height: 40px;
          background-color: #f5f7fa;
          border-radius: 6px;
          outline: none;
          border: none;
          margin-bottom: 10px;
        }
        .tips {
          text-align: left;
          font-size: 14px;
          color: @gray;
        }
        > textarea {
          width: 100%;
          height: 84px;
          padding: 14px;
        }
      }
    }
  }
  .qs-popup-footer {
    margin-top: 38px;
  }
}
.wish-progress-list {
  width: 100%;
  &-item {
    border: solid 1px #ededed;
    border-top: none;
    font-size: 14px;
    margin-bottom: 20px;
    .info-box {
      width: 100%;
      height: 32px;
      background-color: #f5f5f5;
      border: solid 1px #ededed;
      color: @gray;
      padding: 0 15px;
      display: flex;
      align-items: center;
      border-left: none;
      border-right: none;
      justify-content: space-between;
    }
    .progress-detail {
      padding: 0 16px;
      .texts {
        color: @light-black;
        line-height: 1.7;
        width: 574px;
        margin: 14px 0 4px 0;
      }
      .p-and-o {
        display: flex;
        justify-content: space-between;
        padding-bottom: 20px;

        .pic-list {
          flex: 0 0 600px;
          display: flex;
          flex-wrap: wrap;
          > img {
            width: 31%;
            border-radius: 6px;
            height: 116px;
            display: block;
            margin-bottom: 16px;
          }
          > img:not(:nth-child(3n)) {
            margin-right: calc(7% / 2);
          }
        }
        .r-operation {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          > .item {
            color: @black;
            margin-bottom: 12px;
            &:hover {
              color: @red;
            }
          }
        }
      }
    }
  }
}

.claim-wish-table {
  margin-top: 18px;
  .qs-table-th,
  .qs-table-td {
    text-align: left;
  }
  .one {
    width: 154px;
    padding-right: 10px;
  }
  .two {
    width: 227px;
    padding-right: 10px;
  }
  .three {
    width: 158px;
  }
  .four {
    width: 152px;
  }
  .operation-view{
    justify-content: flex-start;
  }
}
.claim-wish-detail {
  .form {
    margin-top: 24px;
    .not-input-default {
      margin-bottom: 20px;
    }
    .img-list {
      display: flex;
      > img {
        margin-right: 17px;
        width: 193px;
        height: 126px;
        border-radius: 4px;
        display: block;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.v-list-table {
  margin-top: 15px;
  text-align: left;
  .one {
    width: 184px;
    padding-right: 10px;
  }
  .two {
    width: 185px;
  }
  .three {
    width: 191px;
  }
  .four {
    width: 212px;
  }
  &.v-a-list-table {
    .one {
      width: 255px;
    }
    .two {
      width: 281px;
    }
    .three {
      width: 159px;
    }
  }
}
.volunteer-qs-form {
  margin-top: 35px;
  .not-input-default {
    margin-bottom: 22px;
  }
}

.user-profile,
.wish-add {
  .form{
    .input-default {
      margin-bottom: 20px;
    }
    .not-input-default{
      margin-bottom: 22px;
    }
  }
}
.has-address {
  .right-input-box {
    .area-address {
      display: flex;
      width: 100%;
      margin-bottom: 12px;
      > .addr-select {
        flex: 1;
      }
    }
  }
}
.wish-add {
  margin: 0 auto;
  padding-top: 24px;
  .has-address {
    .right-input-box {
      height: 40px;
    }
  }
  &.not-p-t{
    padding-top: 0;
  }
}
.progress-step-wrap {
  justify-content: space-between;
  margin: 17px auto 14px auto;
  width: 420px;
  position: relative;
  > .line{
    width: 280px;
    height: 2px;
    background-image: linear-gradient(90deg,
      #ff773f 25%,
      #fe1e2a 100%);
    border-radius: 1px;
    position: absolute;
    left: 52%;
    top: 13px;
    transform: translateX(-50%);
  }
  .step-item {
    font-size: 12px;
    color: @gray;
    .flex-center;
    flex-direction: column;
    .text{
      margin-top: 4px;
    }
    .round-box {
      .flex-center;
      color: #fff;
      border-radius: 50%;
      font-size: 14px;
      width: 28px;
      height: 28px;
      background-color: #b3b3b3;
      box-shadow: 0 0 0px 3px rgba(179, 179, 179, 0.2);
    }
    &.current {
      color: @red;
      .round-box {
        background-image: linear-gradient(90deg, #ff773f 25%, #fe1e2a 100%);
        box-shadow: 0 0 0px 3px rgba(255, 119, 63, 0.2);
      }
    }
  }
}
a.validate-img{
 width: 107px;
}
.process-detail-page{
  margin-bottom: 90px;
  .base-section{
    width: 100%;
    background-color: #ffffff;
    padding: 19px 20px 40px 20px;
  }
  .process-detail{

    .user-info{
      margin: 24px 0 20px 0;
      display: flex;

      .avatar{
        margin-right: 10px;
        > img{
          width: 56px;
          height: 56px;
          border-radius: 50%;
        }
      }
      .meta-content{
        .user-name{
          font-size: 18px;
          color: @black;
        }
        .meta-content-desc{
          margin-top: 2px;
          font-size: 14px;
          color: @gray;
        }
      }
    }
    .process-meta{
      margin-top: 15px;
      margin-bottom: 6px;
      font-size: 18px;
      color: @black;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .process-meta-info{
        display: flex;
        align-items: center;
        color: @blue;
        font-size: 14px;
        > .icon {
          margin-right: 4px;
        }
      }
    }
  }
  .leave-message{
    margin-top: 10px;
    .top-menu{
      > .left-menu{
        display: flex;
        .title{
          font-size: 18px;
          color: #19191a;
          padding-bottom: 14px;
          position: relative;
          margin-right: 10px;
          &::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 3px;
            background-color: #ff0d19;
          }
        }
        .msg-count{
          font-size: 18px;
          color: #999;
        }
      }
    }
  }
}

    .video-js .vjs-time-tooltip, .video-js .vjs-mouse-display:after, .video-js .vjs-play-progress:after{display: none!important;}
    .video-title{font-size: 13px;text-align: center;padding: 3px 0;background-color: #68c258;color: #fff;}
    .video-close {
        position: absolute;
        right: -5px;
        top: -10px;
        width: 20px;
        height: 20px;
        opacity: 0;
        background-color: #333;
        border-radius: 50%;
    }
    .video-close:hover {
        opacity: 1;
    }
    .video-close:before, .video-close:after {
        position: absolute;
        left: 9px;
        top: 5px;
        content: ' ';
        height: 10px;
        width: 2px;
        background-color: #fff;
    }
    .video-close:before {
        transform: rotate(45deg);
        -ms-transform:rotate(45deg); 	/* IE 9 */
        -moz-transform:rotate(45deg); 	/* Firefox */
        -webkit-transform:rotate(45deg); /* Safari 和 Chrome */
        -o-transform:rotate(45deg); 	/* Opera */
    }
    .video-close:after {
        transform: rotate(-45deg);
        -ms-transform:rotate(-45deg); 	/* IE 9 */
        -moz-transform:rotate(-45deg); 	/* Firefox */
        -webkit-transform:rotate(-45deg); /* Safari 和 Chrome */
        -o-transform:rotate(-45deg); 	/* Opera */
    }

#video-popup.popup-wrapper{
  padding: 0;
  width: auto;
  max-width: unset;
  background-color: transparent;
  .popup-content{
    max-height: none;
    margin: 0;
  }
}
.play-icon{
  width: 64px;
  height: 64px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.video-wrapper{
  .video-js .vjs-play-progress::before {
    top: -0.4em;
  }
}
.s-theme-list{
  .item .content{
    .content-bottom{
      .btn{
        width: 106px;
        height: 34px;
      }
    }
  }
  .item .content .content-top {
    .title-wrapper {
      font-weight: bold;
      margin-bottom: 4px;
    }
    .process-wrapper{
      padding-bottom: 20px;
      .process-wrapper__process{
        height: 6px;
      }
    }
    .raise-list{
      padding-top: 17px;
      border-top: 1px solid #f2f2f2;
      padding-bottom: 17px;
      > li{
        display: flex;
        flex-direction: column;
        span{
          &:first-child{
            font-weight: bold;
          }
          &:last-child{
            margin-top: 4px;
          }
        }
      }
    }
    .donate-date-wrap{
      font-size: 14px;
      color: @gray;
    }
    .donate-msg-wrap{
      .line{
        font-size: 14px;
        color: @gray;
        > span {
          color: @light-black;
        }
        &.target{
          > span {
            color: @red;
            font-size: 16px;
            font-weight: bold;
            > span{
              font-size: 14px;
            }
          }
        }
        & + .line{
          margin-top: 8px;
        }
      }
    }
  }
}
.page-detail-layout {
  .s-detail-summary{
    > .content{
      position: relative;
      .donate-msg-wrap{
        .line{
          font-size: 14px;
          color: @gray;
          > span {
            color: @light-black;
          }
          &.target{
            > span {
              color: @red;
              font-size: 16px;
              font-weight: bold;
              > span{
                font-size: 14px;
              }
            }
          }
          & + .line{
            margin-top: 8px;
          }
        }
      }
      .meta{
        .title-box{
          margin-bottom: 8px;
          h2{
            font-size: 24px;
            font-weight: bold;
          }
        }
      }
      .content-bottom{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
      }
      // .raise-list{
      //   padding: 14px 0;
      //   margin-bottom: 18px;
      //   border-bottom: 1px solid @border;
      //   border-top: 1px solid @border;
      //   > li {
      //     font-size: 14px;
      //     color: @gray;
      //     display: flex;
      //     flex-direction: column;
      //     .raise-list-count{
      //       font-weight: bold;
      //       margin-bottom: 6px;
      //       font-size: 16px;
      //       color: @red;
      //       > span{
      //         font-size: 14px;
      //       }
      //     }
      //   }
      // }
    }
    .process-wrapper{
      margin-bottom: 16px;
      .process-wrapper__process{
        height: 5px;
      }
      .process-wrapper__process-bar-point{
        width: 10px;
        height: 10px;
      }
    }
    .donate-wrap{
      .line{
        margin-bottom: 10px;
        display: flex;
        font-size: 14px;
        align-items: center;
        color: @gray;
      }
      .donate-target{
        .r{
          color: @red;
          > .count {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }
      .donate-date{
        .r{
          color: @light-black;
        }
      }
    }
  }
}
.wish-list-wrapper-table{
  .table-r{
    margin-bottom: 0;
    > .item {
      color: @black;
      &:nth-child(1) {
        flex: 0 0 350px;
        width: 350px;
      }
      &:nth-child(2) {
        flex: 0 0 190px;
        width: 190px;
      }
      &:nth-child(3) {
        flex: 0 0 220px;
        width: 220px;
      }
      &:nth-child(4) {
        flex: 1;
        text-align: left;
      }
      &.red{
        color: @red;
      }
    }
    &.line{
      padding: 12px 0;
      font-size: 14px;
    }
  }
}
.with-donate-list{
  &-item{
    font-size: 14px;
    color: @gray;
    padding: 21px 0;
    border-bottom: 1px solid @border;
    display: flex;
    align-items: center;
    .left{
      flex: 0 0 66%;
      display: flex;
      align-items: center;
      .avatar-wrap{
        margin-right: 14px;
        width: 56px;
        height: 56px;
        overflow: hidden;
        border-radius: 50%;
        > img{
          width: 100%;
        }
      }
      .donate-msg{
        .name{
          color: #333333;
          font-size: 16px;
          margin-bottom: 4px;
        }
      }
    }
    .desc{
      flex: 1;
      text-align: right;
      color: @light-black;
    }
    &:first-child{
      padding-top: 10px;
    }
  }
}
