@import "var";

.bg(@src,@imgSuffix: png,@pos: center,@size: cover){
  background: url('@{image_url}@{src}.@{imgSuffix}') no-repeat @pos;
  background-size: @size;
}

.bg-white{
	background-color: #fff;
}

.bg-grey{
	background-color: #f2f4f7;
}

.mb20{
	margin-bottom: 20px !important;
}

.mt40{
	margin-top: 40px !important;
}
.mb40{
  margin-bottom: 40px !important;
}
.flex-center{
	display: flex;
	align-items: center;
	justify-content: center;
}


.basis-xs {
	flex-basis: 20%;
}

.basis-sm {
	flex-basis: 40%;
}

.basis-df {
	flex-basis: 50%;
}

.basis-lg {
	flex-basis: 60%;
}

.basis-xl {
	flex-basis: 80%;
}

.flex-sub {
	flex: 1;
}

.flex-twice {
	flex: 2;
}

.flex-treble {
	flex: 3;
}

.flex-direction {
	flex-direction: column;
}

.flex-wrap {
	flex-wrap: wrap;
}

.align-start {
	align-items: flex-start;
}

.align-end {
	align-items: flex-end;
}

.align-center {
	align-items: center;
}

.align-stretch {
	align-items: stretch;
}

.self-start {
	align-self: flex-start;
}

.self-center {
	align-self: center;
}

.self-end {
	align-self: flex-end;
}

.self-stretch {
	align-self: stretch;
}

.align-stretch {
	align-items: stretch;
}

.justify-start {
	justify-content: flex-start;
}

.justify-end {
	justify-content: flex-end;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.title-desc-item{
	font-size: 14px;
}

.not-margin{
	margin: 0;
}
.bg-baby-blue{
	background-color: #f2f4f7;
}

.fz14{
	font-size: 14px !important;
}

.mt10{
	margin-top: 10px !important;
}

.mb10{
	margin-bottom: 10px !important;
}

.mb50{
	margin-bottom: 50px !important;
}

.mb30{
	margin-bottom: 30px !important;
}

.ml30{
	margin-left: 30px !important;
}


.title-under-green-line{
  border-bottom: 1px solid @dark-red;
  overflow: hidden;
  margin-bottom: 30px;
  @height: 76px;
  @line-height: 81px;
  height: @height;
  line-height: @line-height;
  >.title{
    font-size: 22px;
    color: @dark-red;
    float: left;
    height: @height;
    line-height: @line-height;
  }
  >.more{
    float: right;
    font-size: 16px;
    color: @gray;
  }
  >.desc{
    float: left;
    font-size: 16px;
    color: @gray;
  }
}

.btn-group {
	font-size: 14px;

	.btn {
		height: 35px;
		line-height: 35px;
		width: 145px;

		&:not(.hidden) + .btn {
			margin-left: 75px;
		}
	}

	&.btn-group4 {
		.btn {
			& + .btn {
				margin-left: 20px;
			}
		}
	}
}

.mt30{
	margin-top: 30px !important;
}