aside{
  .aside-share-donate{
    .top-menu{
      margin-bottom: 20px;
    }
    .share-donate-item{
      display: block;
      & + .share-donate-item{
        margin-top: 10px;
      }
    }
    .share-donate{
      display: flex;
      justify-content: space-between;
      & + .share-donate{
        margin-top: 10px;
      }
      .share-img{
        .img-wrap(80px,80px);
        border-radius: 50%;
        border: 1px solid @border;
        margin-right: 20px;
      }
      .share-donate-content{
        font-size: 14px;
        flex-grow: 1;
        border-bottom: 1px solid @border;
        padding: 10px 0;
        .share-donate-content-title{
          color: @black;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        .share-donate-content-desc{
          color: @light-black;
        }
      }
    }
  }
}

.page-gray{
  background: #f2f4f7;
}
.page-index-about{
  padding-top: 30px;
  .container{
    background: white;
    padding: 30px 25px;
    .title-header{
      border-bottom: 1px solid #ededed;
      .title{
        font-size: 28px;
        margin-bottom: 30px;
      }
    }
    .about-body{
      .video-wrapper{
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        margin: 20px 0;
      }
    }
  }
}


.sidebar{
  background: white;
  padding: 20px;
  flex: 1;
  width: 300px;
  float: left;
  .wish-list-wrapper{
    .wish-list{
      margin: 0;
      display: block;
      >li{
        padding: 25px 0;
        margin: 0;
        width: unset;
        border-bottom: 1px solid #ededed;
        &:hover{
          box-shadow: unset;
        }
        a{
          display: flex;
          flex-direction: row;
          align-items: center;
          .img{
            width: 120px;
            height: 80px;
            border-radius: 5px;
            overflow: hidden;
            margin-right: 20px;
          }
          .content{
            height: unset;
            border: unset;
            padding: 0;
            width: 150px;
            .top .title span{
              width: 100%;
              font-size: 16px;
              .text-limit2;
              white-space: unset;
            }
          }
        }
      }
    }
  }
}

.page-story-detail{
  padding-top: 30px;
  .container{
    .title-header{
      border-bottom: 1px solid #ededed;
      .title{
        font-size: 28px;
        margin-bottom: 25px;
      }
      .detail-meta{
        margin-bottom: 25px;
        color: #999;
        font-size: 14px;
        .tag{
          color: #999;
          display: inline-block;
          background: #f2f4f7;
          border-radius: 5px;
          padding: 5px 10px;
          height: unset;
          margin-right: 60px;
        }
      }
    }
    .story-body{
      float: left;
      padding: 25px;
      background: white;
      width: 750px;
      margin-right: 30px;
      .video-wrapper{
        width: 100%;
        border-radius: 5px;
        overflow: hidden;
        margin: 20px 0;
      }
    }
  }
}


.wish-list > li a .img .wish-no{
  position: absolute;
  z-index: 10;
  width: 80px;
  height: 20px;
  background: white;
  opacity: .9;
  color: #6483bb;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  border-radius: 10px;
  right: 10px;
  bottom: 10px;
}

.small-font{
  font-size: 16px;
}

.page-org-index .main-content-wrapper .user-name{
  margin-left: 0!important;
}