@import '../../../commonStyle/var';
@import '../../../commonStyle/tool';
@import './var';

.btn {
  &.bg-red {
    background-color: @red;
    color: #fff;
    border: 1px solid #fff;

  }
  &.btn-gradual-red {
    background-image: linear-gradient(
      90deg,
      #f16d25 0%,
      #ef981d 0%,
      #edc214 0%,
      #f69d2a 0%,
      #ff773f 0%,
      #ff0d19 100%
    );
    color: #fff;
    background-size: auto;
  }
  &.bg-t-red {
    background-color: #fff;
    color: @red;
    border: 1px solid @red;
  }
  &.btn-r {
    border-radius: 6px;
  }
  &.btn-s {
    padding: 0 6px;
    height: 24px;
    font-size: 14px;
    min-width: 80px;
  }
  &.btn-m {
    height: 36px;
    font-size: 14px;
    padding: 0 34px;
  }
  &.btn-b{
    height: 42px;
    font-size: 18px;
    padding: 0 40px;
  }
}
.container {
  width: @container_width;
  margin: 0 auto;
}

textarea{
  padding: 10px;
}

.tab-list {
  .tab-item-content {
    display: none;
    &.current {
      display: block;
    }
  }
}
.qs-form {
  @form-field_width_mr: 10px;
  .btn-input {
    margin-top: 40px;
  }
  .tips-title {
    color: @gray;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 16px;
  }
  .not-input-default {
    margin-bottom: 30px;
    font-size: 14px;
    color: @black;
    display: flex;
    > .label-title {
      margin-right: @form-field_width_mr;
      width: 100px;
      flex-shrink: 0;
      text-align: right;
      &.align-l{
        text-align: left;
      }
    }
    > .text {
      flex: 1;
      &.link {
        &:hover {
          color: @red;
        }
      }
    }
  }
  .select-default {
    width: 100px;
    height: 36px;
    color: @light-black;
    font-size: 14px;
  }
  &.w-550{
    .input-default,.not-input-default{
      textarea,input,.text,.right-input-box,select{
        flex: 0 0 550px;
      }
    }
  }
  .input-default,.form-group-inline,.form-group {
    display: flex;
    margin-bottom: 30px;
    .r-has-check{
      display: flex;
      align-items: center;
      > input[type="checkbox"]{
        flex: 0 0 18px;
        height: 18px;
        background-color: #eeeeee;
        border-radius: 2px;
        border: solid 1px #a3a3a3;
        margin-right: 10px;
        cursor: pointer;
      }
      > label.for-check{
        margin-top: 0;
        margin-right: 0;
        flex: none;
        width: auto;
        cursor: pointer;
      }
      .text_admire{
        font-size: 14px;
        color: @red;
        position: relative;
        cursor: pointer;
        &:after{
          content: '';
          position: absolute;
          left: 0;
          bottom: -2px;
          width: 100%;
          height: 1px;
          border-top: 1px dotted @red;
        }
      }
    }
    .form-field{
      flex: 1;
      &.radio-label-wrapper{
        margin-left: -50px;
        margin-bottom: -10px;
        .radio-label{
          display: inline-flex;
          align-items: center;
          cursor: pointer;
          margin-left: 50px;
          margin-bottom: 10px;

          .radio-input{
            cursor: pointer;
            width: 24px;
            height: 24px;
          }
        }
      }

    }
    .tips-text {
      font-size: 14px;
      color: @gray;
    }
    label,.field-name {
      margin-right: @form-field_width_mr;
      font-size: 14px;
      color: @black;
      margin-top: 12px;
      width: 100px;
      flex-shrink: 0;
      text-align: right;
      &.align-l{
        text-align: left;
      }
    }
    > input,
    textarea,
    select,
    .input,.form-field input,.form-field select {
      width: 100%;
      border: none;
      padding: 0 13px;
      background-color: #f5f7fa;
      border-radius: 6px;
      color: @gray;
      height: 40px;
      &.sm-input{
        flex: 0 0 300px;
      }
    }
    .input-default-text{
      font-size: 14px;
      color: @black;
      margin-top: 12px;
      line-height: 1.5;
    }
    .right-input-box{
      flex: 1;
      textarea,input{
        width: 100%;
      }
      > .tips-text{
        position: static;
        margin-top: 4px;
      }
    }
    &.position {
      position: relative;
      .p-r-item {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .not-margin {
    margin: 0;
  }
  button[type='submit'] {
    background-image: linear-gradient(
      90deg,
      #f16d25 0%,
      #ef981d 0%,
      #edc214 0%,
      #f69d2a 0%,
      #ff773f 0%,
      #ff0d19 100%
    );
    height: 40px;
    font-size: 14px;
  }
  .btn {
    &.btn-m {
      padding: 0;
      width: 130px;
    }
    & + .btn {
      margin-left: 30px;
    }
  }
}
.qs-table {
  table-layout: fixed;
  width: 100%;
  margin-bottom: 50px;
  .qs-table-th {
    font-size: 14px;
    color: @black;
    font-weight: bold;
  }
  .qs-table-td {
    font-size: 14px;
    color: @black;
    padding: 18px 0;
  }
  .qs-table-tr {
    border-bottom: 1px solid @border;
  }
}
//popup-reset start
.popup-wrapper{
  padding: 0 35px 35px;
  .icon-close{
    right: 0;
    top: -65px;
  }
  .popup-header{
    padding: 25px 0 10px;
    border-bottom: 1px solid @border;
    h4.title{
      font-size: 18px;
      color: @black;
      line-height: 1.5;
      padding-right: 40px;
      span{
        display: inline;
      }
    }
  }
  .popup-content{
    padding-left: 0;
    padding-right: 0;
  }
  &.popup-wrapper-420{
    width: 420px;
    padding: 0 20px 70px 20px;
    .form-group{
      margin-bottom: 7px;
      .field-name{
        width: 95px;
      }
    }
    .form-btn-group{
      margin-top: 40px;
      padding-left: 95px;
      .btn{
        padding: 0;
        width: 80px;
        height: 28px;
        font-size: 14px;
        & + .btn{
          margin-left: 50px;
        }
      }
    }
  }
}
//popup-reset end

.qs-popup-wrapper {
  padding: 56px 40px;
  .qs-popup-content {
    > .qs-title {
      font-size: 18px;
      color: @black;
      font-weight: bold;
    }
    > .desc {
      font-size: 14px;
      color: @light-black;
    }
  }
  .qs-popup-footer {
    margin-top: 50px;
    .btn {
      font-size: 14px;
      padding: 0;
      width: 80px;
      height: 28px;
      display: inline-flex;
      align-items: center;
      & + .btn {
        margin-left: 60px;
      }
      &.icon-close {
        position: static;
      }
      &.btn-red {
        background-image: linear-gradient(
          90deg,
          #f16d25 0%,
          #ef981d 0%,
          #edc214 0%,
          #f69d2a 0%,
          #ff773f 0%,
          #ff0d19 100%
        );
      }
    }
  }
  &.width-s {
    max-width: 420px;
    width: 420px;
  }
  &.align-center {
    align-items: center;
    display: flex;
    flex-direction: column;
    > .qs-popup-content {
      text-align: center;
    }
  }
}

div.msgBox {
  .msgBoxTitle {
    font-size: 14px;
    color: #19191a;
    padding-bottom: 10px;
    font-weight: bold;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 8%;
      height: 3px;
      background-color: #ff0d19;
    }
  }
  .msgBoxContent {
    margin: 0;
    padding: 20px 0;
    width: 100%;
    text-align: center;
    span {
    }
  }
  .msgBoxButtons {
    input[type='button'] {
      background-image: linear-gradient(
        90deg,
        #f16d25 0%,
        #ef981d 0%,
        #edc214 0%,
        #f69d2a 0%,
        #ff773f 0%,
        #ff0d19 100%
      );
      border: none;
    }
  }
}

.area-select{
  .addr-select{
    flex-grow: 1;
    & + .addr-select{
      margin-left: 10px;
    }
  }
}

.user-info{
  .avatar{
    .avatar(55px);
    margin-right: 20px;
  }
  .meta-content{
    padding-top: 4px;
    .meta-content-top{
      font-size: 18px;
      .user-name{
        margin-bottom: 5px;
      }
      .donate-count{
        margin-bottom: 5px;
        font-size: 14px;
        flex-shrink: 0;
        text-align: right;
        span{
          display: inline;
          margin-left: 3px;
        }
      }
      .msg-type{
        font-size: 14px;
      }
    }
    .meta-content-desc{
      font-size: 14px;
    }
  }
}

.msg-list{
  >li{
    padding: 15px 0 20px;
    min-height: 55px;
    &.no-reply{
      .content{
        //margin-top: -25px;
        padding-top: 0;
      }
    }
    .content{
      padding-top: 10px;
      padding-left: 75px;
      .desc{
        font-size: 14px;
        padding: 12px;
        border-radius: 4px;
        margin-bottom: 10px;
        &:before{
          content: '';
          .triangle-bottom;
          border-bottom: 13px solid #f5f7fa;
          border-top: 8px solid transparent;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          position: absolute;
          left: .2rem;
          top: -18px;
        }
      }
      >.time{
      }
      .time{
        font-size: 14px;
        line-height: 1.2;
      }
      .support-wish{
        font-size: 14px;
        margin-top: 7px;
      }
      .bottom{
        font-size: 14px;
        .oprea{
          margin-left: 10px;
        }
      }
    }
  }
}

.no-data{
  text-align: center;
  color: @light-black;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  margin-top: 25px;
}

select{
  padding: 0 10px;
  font-size: 14px;
}

.title-bar{
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-wrapper{
    display: flex;
    align-items: center;
  }
  .iconfont-wrapper{
    color: #ec6350;
    font-size: 35px;
    line-height: 1;
    .iconfont{
      font-size: 28px;
      margin-right: 3px;
    }
  }
  .icon-wrapper{
    display: flex;
    align-items: center;
    .icon{
      width: 35px;
    }
  }
  &__title{
    font-size: 22px;
    color: @black;
  }
  &__more{
    font-size: 14px;
    color: @gray;
  }
}

.activity-list-wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: -20px;

  .activity-item{
    display: flex;
    width: 530px;
    height: 120px;
    cursor: pointer;
    margin-top: 20px;

    &:hover{
      border-color: transparent;
      box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
    }

    .cover{
      width: 180px;
      height: 120px;
      border-radius: 3px;
    }

    .info-wrapper{
      display: flex;
      flex-direction: column;
      padding: 4px 10px 3px 17px;
      line-height: 1;

      .title{
        width: 350px;
        height: 31px;
        font-size: 18px;
        margin-bottom: 49px;
      }

      .date{
        font-size: 14px;
        color: @black;
        margin-bottom: 20px;
      }

      .label{
        color: @gray
      }

      .stat{
        font-size: 14px;
        color: @red;
      }
    }


  }
}


.tag{
  padding-left: 10px;
  padding-right: 20px;
  height: 24px;
  display: flex;
  align-items: center;
  font-size: 14px;
}

.btn-group{
  margin-left: -30px;
  justify-content: center;
  &.btn2{
    .btn{
      width: 130px;
    }
  }
  .btn{
    margin-left: 30px;
    flex-grow: 0;
  }
}

.process-list{
  >li{
    padding: 25px 0;
    border-bottom: 1px solid @border;
    &:first-child{
      padding-top: 0;
    }
    .meta{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
      .left{
        display: inline-flex;
        align-items: center;
        .time{
          color: @black;
          font-size: 16px;
        }
        .org{
          margin-left: 15px;
          display: inline-flex;
          align-items: center;
          color: @blue;
          font-size: 14px;
          .icon{
            margin-right: 5px;
          }
        }
      }
      .go-info{
        font-size: 14px;
        color: @gray;
        &:hover{
        color: @red;
        }
      }
    }
    .desc{
      margin-bottom: 10px;
      font-size: 14px;
      color: @light-black;
      line-height: 1.5;
      .text-limit2;
    }
  }
}

.img-list{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: -15px;
  margin-left: -25px;
  >li{
    cursor: pointer;
    border-radius: 4px;
    margin-bottom: 15px;
    margin-left: 25px;
    .img-wrap(192px, 125px);
  }
}

aside{
  background-color: #fff;
  width: 295px;
  padding: 20px;
  padding-bottom: 60px;
  .aside-org-info{
    .top-menu{
      margin-bottom: 20px;
    }
    .org-type-desc{
      font-size: 14px;
      color: @gray;
      display: flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .org-info{
      display: flex;
      justify-content: space-between;
      .org-img{
        .img-wrap(80px,80px);
        border-radius: 4px;
        border: 1px solid @border;
        margin-right: 20px;
        padding: 5px;
      }
      .org-info-content{
        font-size: 14px;
        flex-grow: 1;
        border-bottom: 1px solid @border;
        .org-info-content-title{
          color: @black;
          line-height: 1.5;
          margin-bottom: 10px;
        }
        .org-info-content-desc{
          color: @light-black;
        }
      }
    }
  }
}

.img-list{
  display: flex;
  align-items: flex-start;
  margin-left: -20px;
  margin-bottom: -15px;
  &.auto-size{
    >li{
      width: auto;
      height: auto;
    }
  }
  >li{
    border-radius: 4px;
    .img-wrap(180px, 116px);
    margin-left: 20px;
    margin-bottom: 15px;
    img{

    }
  }
}

.page-detail-layout{
  padding-top: 25px;
  .detail-summary{
    padding: 20px;
    margin-bottom: 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    .cover{
      .img-wrap(430px,280px);
      border-radius: 4px;
      border: 1px solid @border;
      position: relative;
      margin-right: 40px;
      .tag{
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .content{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      .title-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        h2{
          margin-right: 15px;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.5;
          color: @black;
        }
      }
      .share-box{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
        position: relative;
        &:hover{
          .qr-code-big{
            display: inline-flex;
          }
        }
        .img{
          .img-wrap(60px,60px);
          margin-right: 10px;
        }
        .desc{
          font-size: 12px;
          color: @gray;
          line-height: 1.5;
        }
        .qr-code-big{
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.16);
          position: absolute;
          left: 0;
          top: 105%;
          z-index: 2;
          max-width: unset;
          max-height: unset;
          background-color: #fff;
          .img-wrap(140px,140px);
          display: none;
        }
      }
      .bottom{
        padding-top: 15px;
        border-top: 1px solid @border;
        margin-top: 15px;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        height: 104px;
        flex-grow: 1;
        .bottom-left{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          .btn-group{
            .btn{
              width: 130px;
              flex-grow: 0;
            }
          }
        }
      }
    }
  }
}

.content-wrapper{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .main-content-wrapper, .full-content-wrapper{
    background-color: #fff;
    padding: 20px;
    padding-bottom: 60px;
    .top-menu{
      margin-bottom: 20px;
      justify-content: flex-start;
      .tab-menu{
        & + .tab-menu{
          margin-left: 135px;
        }
      }
      &.top-menu-space-between{
        justify-content: space-between;
        .right{
          display: flex;
          align-items: center;
          margin-top: -10px;
          margin-bottom: 10px;
          .icon-desc-item{
            margin-right: 20px;
            .icon-desc-item__desc{
              color: @gray;
              font-size: 14px;
            }
          }
          .btns{
            display: inline-flex;
            align-items: center;
            .btn{
              padding-left: 10px;
              padding-right: 10px;
              height: 30px;
              & + .btn{
                margin-left: 22px;
              }
            }
          }
        }
      }
    }
  }
  .main-content-wrapper{
    width: 755px;
  }
  .full-content-wrapper{
    flex-grow: 1;
  }
  aside,.aside{
    width: 295px;
  }
}

.opera-popup{
  width: 420px;
  padding: 55px 30px;
  &__header{
    margin-bottom: 12px;
    &-title{
      font-size: 18px;
      color: @black;
      line-height: 1.5;
      text-align: center;
      font-weight: bold;
    }
  }
  &__content{
    textarea{
      height: 85px;
    }
    &_meta{
      display: block;
      font-size: 14px;
      color: @black;
      margin-bottom: 5px;
      line-height: 1.5;
    }
    .btn-group{
      margin-left: 0;
      justify-content: space-between;
      margin-top: 20px;
      .btn{
        .btn.btn-s;
        flex-grow: 0;
        height: 36px;
        & + .btn{
          margin-left: 0 !important;
        }
      }
    }
  }
}

.page-panel{
  background-color: #fff;
  border-radius: 4px;
  padding: 20px 20px 60px;
}

.form-block{
  padding-bottom: 20px;
  border-bottom: 1px solid @border;
  margin-bottom: 30px;
}

.input-unit{
  input{
  }
  .unit{
    font-size: 14px;
    width: 30px;
  }
}

.pay-list{
  display: flex;
  align-items: center;
  >li{
    width: 120px;
    height: 40px;
    cursor: pointer;
    border: 1px solid @border;
    border-radius: 4px;
    .flex-center;
    label{
      text-align: center!important;
      margin-top: 0!important;
      margin-right: 0 !important;
    }
    & + li {
      margin-left: 30px;
    }
    &.active{
      border-color: @red;
    }
    img{
      max-width: 80%;
      max-height: 70%;
    }
  }
}

.summary-list {
  > li {
    padding: 0 20px;
    &:first-child{
      padding-left: 0;
    }
    &:last-child{
      padding-right: 0;
    }
    .summary-list__title {
      font-size: 14px;
    }

    .summary-list__desc {
      font-size: 18px;
    }

  }

  &.summary-list-reverse {
    > li {

      .summary-list__title {
      }

      .summary-list__desc {
      }
    }
  }
}

.appro-table{
  width: 100%;
  tr{
    th{
      text-align: left;
      font-size: 16px;
      padding-bottom: 5px;
      &:last-child{
        text-align: right;
      }
    }
    line-height: 1.5;
    &:first-child{
      td{
        padding-top: 0;
      }
    }
    & + tr{
      td{
        padding-top: 20px;
      }
    }
    td{
      font-size: 14px;
      &:last-child{
        padding-right: 0;
        text-align: right;
      }
      &.appro-title{
        width: 345px;
        color: @black;
      }
      &.appro-time{
        width: 100px;
        color: @light-black;
        white-space: nowrap;
      }
      &.appro-cost{
        color: @red;
      }
    }
  }
}

aside{
  .aside-item{
    & + .aside-item{
      margin-top: 35px;
    }
  }
}

.theme-list{
  >li{
    border: 1px solid @border;
    transition: all .3s;
    &:hover{
      border-color: transparent;
      box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
    }
    & + li{
      margin-top: 40px;
    }
    a{
      display: flex;
      padding: 20px;
      .img{
        .img-wrap(430px, 280px);
        margin-right: 40px;
        border-radius: 4px;
        position: relative;
        .tag{
          position: absolute;
          left: 0;
          top: 0;
        }
      }
      .content{
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .content-top{
          .title-wrapper{
            line-height: 1.5;
            font-size: 24px;
            color: @black;
            margin-bottom: 10px;
            .title{
              display: inline-block;
              width: 568px;
              .text-overflow;
            }
          }
          .raise-list{
            font-size: 16px;
            padding-bottom: 24px;
            border-bottom: 1px solid @border;
            margin-bottom: 20px;
          }
          .summary-title{
            font-size: 16px;
            color: @black;
            margin-bottom: 10px;
          }
          .summary-content{
            font-size: 14px;
            color: @light-black;
            margin-bottom: 15px;
          }
        }
        .content-bottom{
          .btn{
            font-size: 14px;
            width: 88px;
            height: 28px;
            padding: 0;
          }
        }
      }
    }
  }
}

.select-list{
  display: flex;
  align-items: center;
  font-size: 14px;
  >li{
    a{
      width: 80px;
      height: 36px;
      border-radius: 4px;
      .flex-center;
      color: @light-black;
      background-color: #f5f5f5;
      border: 1px solid #ededed;
    }
    & + li{
      margin-left: 20px;
    }
    &.active{
      a{
        background-color: @red;
        color: #fff;
        border-color: @red;
      }
    }
  }
}
.base-table{
  .table-r{
    font-size: 16px;
    color: @black;
    margin-bottom: 10px;
    > .item {
      .text-cut;
    }
    &.line{
      margin-bottom: 0;
      font-size: 14px;
      color: @light-black;
      padding: 16px 0;
    }
  }
}

.story-list{
  .list(260px, 166px, 30px, 30px);
  >li{
    & + li{
      margin-top: 30px;
    }
    a {
      transition: all .3s;
      display: flex;
      align-items: center;
      &:hover{
        box-shadow: 0 0 20px #ccc;
        .img{
          img{
            transform: scale(1.05);
          }
        }
        .content{
          .title{
            color: @red;
          }
        }
      }
      .img{
        img{
          transition: all .3s;
        }
      }
      .content{
        display: flex;
        flex-direction: column;
        font-size: 14px;
        .title{
          font-size: 18px;
          line-height: 1.5;
          color: @black;
        }
        .time{
          color: @gray;
        }
        .tag{
          display: inline-block;
          background: #f2f4f7;
          color: #999;
          border-radius: 5px;
          padding: 2px 10px;
          height: unset;
          font-size: 14px;
        }
      }
    }
  }
}

.select-menu-list{
  display: flex;
  & + &{
    margin-top: 15px;
  }
  .text{
    font-size: 14px;
    flex-shrink: 0;
    color: @light-black;
    margin-right: 15px;
  }
  .query-ul{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 14px;
    color: @black;
    margin-left: -10px;
    margin-bottom: -5px;
    >li{
      margin-left: 10px;
      margin-bottom: 5px;
      line-height: 1.5;
      &:hover,&.active{
        a{
          color: #fff;
          background-color: @red;
        }
      }
      a{
        transition: all .3s;
        border-radius: 4px;
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding-left: 7px;
        padding-right: 7px;
      }
    }
  }
}

.input-range-wrapper{
  display: inline-flex;
  align-items: baseline;
  position: relative;
  z-index: 2;
  font-size: 14px;
  .text{
    color: @light-black;
  }
  &:hover{
    .input-range-box{
      .input-range-content{
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.12);
        .input-range-content-bottom{
          display: flex;
        }
      }
    }
  }
  .input-range-box{
    position: relative;
    width: 155px;
    display: flex;
    height: 28px;
    .input-range-content{
      border-radius: 4px;
      padding: 4px 8px;
      transition: all .3s;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-color: #fff;
      .input-range-content-top{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .input-default-box{
          position: relative;
          .input-default-box-text{
            .flex-center;
            width: 22px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            color: @black;
          }
          .input-default{
            margin: 0;
            width: 60px;
            height: 28px;
            padding-left: 22px;
            padding-right: 5px;
          }
        }
        .connect-code{
          width: 20px;
          .flex-center;
        }
      }
      .input-range-content-bottom{
        display: none;
        align-items: center;
        justify-content: space-between;
        .clear-text{
          color: @blue;
        }
        .conform-text{
          color: #fff;
          background-color: @red;
          border-radius: 4px;
          height: 25px;
          padding-left: 7px;
          padding-right: 7px;
          .flex-center;
          display: inline-flex;
          cursor: pointer;
        }
      }
    }
  }
}

.price-list{
  margin-bottom: -30px;
  margin-left: -25px;
  display: flex;
  flex-wrap: wrap;
  >li{
    margin-bottom: 30px;
    margin-left: 25px;
    width: 220px;
    &:hover{
      a{
        .img{
          img{
            transform: scale(1.05);
          }
        }
        .content{
          .title{
            color: @red;
          }
        }
      }
    }
    a{
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
      .img{
        .img-wrap(100%, 144px);
        img{
          transition: all .3s;
        }
      }
      .content{
        padding: 19px 16px 0;
        display: flex;
        flex-direction: column;
        height: 86px;
        .title{
          font-size: 18px;
          color: @black;
          .text-overflow;
          margin-bottom: 7px;
        }
        .bottom{
          display: flex;
          align-items: center;
          .title-desc-item{
            width: 50%;
          }
        }
      }
    }
  }
}

.form-group-title{
  font-size: 16px;
  color: @black;
  margin-bottom: 15px;
}

.table-default{
  font-size: 14px;
  width: 100%;
  text-align: left;
  tr{
    border: 1px solid @border;
  }
  th,td{
    vertical-align: middle;
    &:first-child{
      padding-left: 25px;
    }
  }
  th{
    color: @gray;
    background-color: #f5f5f5;
    height: 28px;
  }
  td{
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 10px;
  }
}

.form-meta{
  display: flex;
  align-items: center;
  margin-left: -30px;
  margin-bottom: -5px;
  margin-top: 20px;
  &__item{
    height: 36px;
    margin-left: 30px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    select{
      padding-left: 10px;
      padding-right: 10px;
      height: 35px;
    }
  }
}

.num-toggle-render{
  height: .6rem;
  .current-number-wrapper{
    input{
      width: 73px;
      height: 36px;
    }
  }
  .btn-num-update {
    height: 36px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .add,.subtract{
      width: 26px;
      height: 17px;
    }
  }
}

.perfect-scrollbar-container{
  position: relative;
}

.abs-center {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}

.abs-left-center {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	transform-origin: 50% 51%;
}

.abs-right-center {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform-origin: 50% 51%;
	right: 0;
}

.abs-right-top {
	position: absolute;
	top: 0;
	right: 0;
}

.clearfix {
	clear: both;
}

.summary-info{
	background-color: #fafafa;
	border-top: 1px solid @border;
	border-bottom: 1px solid @border;
	.container{
	  height: 90px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	}
	.site-summary-list{
	  display: flex;
	  align-items: center;
    flex-grow: 1;
	  >li{
      display: flex;
      align-items: baseline;
      flex-grow: 1;
      padding: 0 35px;
      flex-shrink: 0;
      &:first-child{
        padding-left: 0;
      }
      & + li{
        border-left: 1px solid @border;
      }
      .site-summary-title{
        font-size: 16px;
        color: @black;
      }
      .site-summary-num{
        font-size: 26px;
        color: @red;
      }
      .site-summary-unit{
        font-size: 14px;
        color: @red;
      }
	  }
	}
	.right{
	  display: inline-flex;
	  align-items: center;
	  .btn{
      width: 140px;
      height: 40px;
      & + .btn{
        margin-left: 15px;
      }
	  }
	}
}

//多行裁剪
.multi-text-overflow(@line-height,@num) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: @num;
	-webkit-box-orient: vertical;
	line-height: @line-height;
	height: @line-height * @num;
}

.form-desc-list {
  color: @black;
  @width: 105px;

  > li {
    overflow: hidden;
    margin-bottom: 30px;

    > .title, .content > .title {
      float: left;
      width: @width;
      text-align: right;
      font-size: 14px;
    }

    > .desc, .content > .desc {
      margin-left: @width;
      font-size: 16px;
    }

    .content {
      margin-bottom: 5px;
    }

    .msg {
      margin-left: @width;
      font-size: 0;

      i {
        float: left;
      }

      .desc {
        font-size: 14px;
        padding-left: 18px;
        color: @gray;
      }
    }
  }
}