@import "./var";
@import "./icon";
@import "./tool";

.sx-page{
	margin-top: 30px;

	.banner{
		margin-bottom: 30px;
	}

	.stat-container{
		display: flex;
		background-color: #fafafa;
		border: 1px solid @border;
		width: 1080px;
		margin: 0 auto;
		height: 90px;

		.site-summary-list{
			display: flex;
			align-items: center;
			flex-grow: 1;
			>li{
				display: flex;
				align-items: baseline;
				flex-grow: 1;
				padding: 0 35px;
				flex-shrink: 0;
				justify-content: center;
				&:first-child{
					padding-left: 0;
				}
				& + li{
					border-left: 1px solid @border;
				}
				.site-summary-title{
					font-size: 16px;
					color: @black;
				}
				.site-summary-num{
					font-size: 26px;
					color: @red;
				}
				.site-summary-unit{
					font-size: 14px;
					color: @red;
				}
			}
		}
	}

	.activity-list{
		display:flex;
		flex-wrap: wrap;
		margin-bottom: -26px;

		.item{
			width: 344px;
			height: 422px;
			display: flex;
			border: 1px solid @border;
			border-radius: 7px;
			flex-direction: column;
			margin-bottom: 26px;

			&:hover{
				border-color: transparent;
				box-shadow: 0 2px 20px 2px rgba(100, 131, 187, 0.35);
			}

			&:nth-child(3n+1){
				margin-right: 24px;
			}
			&:nth-child(3n+2){
				margin-right: 24px;
			}

			.img{
				width: 342px;
				height: 223px;
				overflow: hidden;
				border-radius: 4px 4px 0 0;
			}

			.content{
				padding: 20px 15px 20px 15px;
				display: flex;
				flex-direction: column;
				line-height: 1;

				h3{
					margin-bottom: 23px;
				}

				.activity-info{
					display: flex;
					flex-direction: column;

					.upper{
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-bottom: 19px;
					}

					.address, .time, .number{
						display: flex;
						align-items: center;
						>i{
							margin-right: 5px;
						}
					}

					.lower{
						margin-bottom: 22px;
					}

					.initiator-title{
						padding-top: 17px;
						border-top: 1px solid @border;
					}
				}
			}
		}

	}
}

.activity-detail{
	background-color: #f2f4f7;

	.wrapper{
		width: 1080px;
		margin: 0 auto;
		background-color: white;
		margin-top: 30px;
		margin-bottom: 83px;

		.header{
			padding: 33px 0px 40px 0px;
			margin: 0px 30px 0px 30px;
			display: flex;
			border-bottom: 4px solid #f8f8f8;

			.img{
				width: 460px;
				height: 360px;
				border-radius: 6px;
			}

			.activity-summary-info{
				padding: 21px 0px 10px 21px;
				display: flex;
				flex-direction: column;
				line-height: 1;
				background-color: white;
				position: relative;
				width: 560px;
				height: 359px;

				h1{
					margin-bottom: 35px;
					font-size: 28px;
					font-weight: bold;
					color: @black;
				}

				.term{
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					font-size: 16px;

					.iconfont{
						font-size: 28px;
						color: @red;
						margin-right: 13px;
					}

					.title{
						color: @red;
					}

					.desc{
						color: @gray;
					}
				}

				.btn{
					margin-top: 40px;
					width: 150px;
					height: 42px;
				}

				.qr-code-container{
					position: absolute;
					width: 190px;
					height: 96px;
					border: 1px solid @border;
					right: 0;
					bottom: 0;
					padding: 14px 17px 17px 14px;
					display: flex;
					align-items: flex-end;
					&:hover{
						.big-img-box{
							display: block;
						}
					}

					.big-img-box{
						position: absolute;
						top: 100px;
						left: 0;
						display: none;
						background-color: #fff;
						.big-img{

						}
					}



					.img-box{
						width: 68px;
						height: 68px;
						margin-right: 20px;
					}

					.tips{
						font-size: 14px;
						color: @gray;

						div{
							margin-bottom: 7px;
						}
					}
				}
			}
		}

		.body{
			padding: 37px 29px 37px 29px;
			display: flex;
			line-height: 1;
			min-height: 567px;


			.left{
				width: 671px;
				margin-right: 49px;

				.nav{
					border-bottom: 1px solid #e9e9e9;

					li{
						text-align: center;
						font-size: 18px;
						display: inline-block;
						padding: 0px 0px 20px 0px;
						margin-right: 50px;

						&.active{
							border-bottom: 2px solid @red;
						}
					}
				}

				.record-content{
					padding-top: 24px;
					padding-bottom: 55px;
					display: flex;
					flex-direction: column;

					.item{
						display: flex;
						align-items: center;
						justify-content: center;
						padding-bottom: 20px;
						padding-top: 20px;
						border-bottom: 1px solid @border;

						.avatar{
							width: 70px;
							height: 70px;
							overflow: hidden;
							border-radius: 50%;
							margin-right: 22px;
						}

						.name{
							width: 515px;

							h4{
								line-height: 35px;
								font-size: 16px;
							}
							p{
								line-height: 35px;
								color: @gray;
								font-size: 14px;
							}
						}

						.status{
							font-size: 16px;
							color: @red;
						}
					}
				}

				.load-more{
					display: flex;
					justify-content: center;
					color: @red;
					font-size: 16px;
					margin-bottom: 24px;
					cursor: pointer;
				}
			}

			.right{
				width: 300px;

				.title-under-line{
					width: 100%;
					border-bottom: 1px solid @border;
					.title{
						height: 100%;
						font-size: 18px;
						display: inline-block;
						padding: 0px 0px 20px 0px;

						border-bottom: 2px solid @red;
					}
				}

				.publishorg-wrapper{
					margin-top: 30px;
					display: flex;
					flex-grow: 1;

					.img{
						margin-right: 10px;
					}

					.name{
						display: flex;
						flex-direction: column;

						h3{
							font-weight: bold;
							font-size: 18px;
							line-height: 1.5;
							margin-bottom: 15px;
						}

						.divider{
							border-bottom: 2px solid @red;
							width: 35px;
						}

						p{
							padding-top:15px;
							line-height: 25px;
							font-size: 14px;
							color: @gray;

							span{
								color: @red;
							}
						}
					}
				}
			}
		}
	}
}

//项目列表
.project-list {
	overflow: hidden;
	margin-left: -20px;
	margin-bottom: -20px;

	&.help-list { //善助
		.item {
			height: auto;
		}
	}

	&.donate-list { //善捐
		.item {
			height: 300px;
		}
	}

	.item {
		height: 314px;
		margin-left: 20px;
		padding: 10px;
		float: left;
		box-sizing: border-box;
		width: 240px;
		border: 1px solid @border;
		transition: all .3s;
		margin-bottom: 20px;

		&:hover {
			border: 1px solid @green;
		}

		a {
			> .img {
				.img-wrap(100%, 135px);
				position: relative;

				.tag {
					position: absolute;
					right: 0;
					top: 0;
					font-size: 12px;
					color: #fff;
					background-color: #fd7400;
					height: 25px;
					line-height: 25px;
					border-radius: 0;
					padding: 0 10px;
				}
			}

			> .content {
				margin-top: 20px;
				text-align: left;

				h3 {
					height: 48px;
					font-size: 16px;
					margin-bottom: 10px;
					font-weight: 400;
					word-break: break-all;
				}
			}

			.bottom {
				border-top: 1px solid @border;
				margin-top: 15px;
				padding-top: 10px;
			}

			.helper-info {
				margin-top: 10px;
			}

			.target {
				line-height: 1.2;
				font-size: 14px;
				margin-top: 10px;

				span {
					line-height: 1.2;
				}

				.title {
					color: @gray;
					padding-right: 5px;
					margin-right: 5px;
					border-right: 1px solid @gray;
				}
			}

			.target-inline {
				overflow: hidden;

				.target {
					margin-top: 0;
					float: left;
					width: 50%;
					box-sizing: border-box;

					& + .target {
						border-left: 1px solid @border;
					}

					&:last-child {
						text-align: right;
					}
				}
			}

			.desc {
				margin-top: 3px;
				margin-bottom: 0;
				color: @light-black;
				.text-overflow;
			}

			.initiator-title {
				margin-top: 10px;
				padding-top: 10px;
				border-top: 1px solid @border;
				color: @light-black;
				.text-overflow;
			}

			.activity-info {
				color: @gray;
				font-size: 14px;

				.time {
					float: left;
				}

				.number {
					float: right;
				}

				.address {
					clear: both;
					padding-top: 5px;
					.text-overflow;
				}
			}

		}
	}

	//jb
	&.support-project-list {
		.item {
			a {
				width: 223px;

				> .img {
					height: 145px;
					width: 220px;
				}

				> .content {
					h3 {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.target {
					.title {
						border-right: 1px solid #ddd;
						padding-right: 7px;
						margin-right: 7px;

						span {
							vertical-align: middle;
						}
					}
				}

				.initiator-title {
					.text-desc {
						color: @gray;
						vertical-align: top;
					}
				}

			}

		}
	}

	&.apply-list.signin-list {
		.item {
			padding-bottom: 0;

			a {
				display: inline-block;
				height: inherit;
				width: 223px;

				> .img {
					width: 220px;
					height: 152px;
				}

				> .content {
					h3 {
						overflow: hidden;
						text-overflow: ellipsis;
						margin-bottom: 5px;
					}
				}

				.target {
					margin-top: 5px;
					text-align: left;
					padding-left: 10px;

					&:first-child {
						//padding-right: 10px;
						padding-left: 0;

						span.text-unit {
							display: inline-block;
							max-width: 61px;
							height: 16px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}

					span {
						vertical-align: middle;
					}

					.text-slogan {
						overflow: hidden;
						text-overflow: ellipsis;
						width: 190px;
						display: inline-block;
					}
				}

				.target-inline {
					padding-top: 8px;
					padding-bottom: 10px;
				}
			}
		}

		&.person-signin-list {
			.item {
				height: 320px;

				a {
					width: 223px;

					.img {
						img {
							width: 152px;
						}
					}
				}
			}
		}
	}
}

.project-search{
	.search-wrapper{
		margin-bottom: 42px;

		form{
			display: flex;
			justify-content: space-between;

			.left-wrapper{
				width: 69%;
				display: flex;
				align-items: center;
				.select{
					width: 30%;
					margin-right: 2.9%;
					height: 39px;
					border: 1px solid @border;
					background-color: white;
				}
			}

			.right-wrapper{
				display: flex;
				align-items: center;
				width: 31%;
				position: relative;
				.search-keyword{
					width: 100%;
					height: 39px;
					background-color: white;
					padding-left: 16px;
					padding-right: 30px;
				}

				button[type=submit]{
					position: absolute;
					height: 100%;
					right: 0;
					width: 30px;
					top: 0;
					background-color: transparent;
					cursor: pointer;
					.icon-sousuo{
						color: @gray;
						font-size: 30px;
					}
				}
				.btn-release-activity{
					width: 30%;
					height: 39px;
					flex-shrink: 0;
				}
			}

			.btn-release-activity{
				height: 39px;
				margin-left: 15px;
				width: 200px;
			}

		}

	}

}

.project-search-list {
	&.help-list { //善助
		.item {
			height: auto;
		}
	}

	&.donate-list { //善捐
		.item {
			height: 310px;
		}
	}

	.item {
		width: 255px;
		padding: 15px;
	}

	& + .pagination {
		margin-top: 40px;
	}
}

.activity-detail {
	//padding-top: 20px;
	.summary {
	  zoom: 1;
	  display: flex;
	  margin-bottom: 40px;
	  .img {
		flex-shrink: 0;
		float: left;
		width: 460px;
		position: relative;
		padding-top: 2px;
		margin-right: 20px;
		img {
		  .img-wrap(100%, 284px);
		}
	  }
	  .img-wrap {
		width: 285px;
		height: 285px;
		border-radius: 50%;
		img {
		  height: 100%;
		  width: 100%;
		  border-radius: 50%;
		}
	  }
	  .content {
		flex-shrink: 0;
		float: right;
		width: 600px;
		height: 284px;
		font-size: 14px;
		position: relative;
		h1 {
		  font-size: 24px;
		  color: #68c258;
		  margin-bottom: 20px;
		}
		.meta {
		  &.other-activity-meta {
			position: relative;
			.progress-bar {
			  position: absolute;
			  right: 0;
			  top: 50%;
			  transform: translateY(-50%);
			  .circle-cover {
				width: 87px;
				height: 87px;
				border: 3px solid #fd7400;
				border-radius: 50%;
				position: absolute;
				z-index: 9999;
				left: 0;
				top: 0;
			  }
			  .circle {
				width: 87px;
				height: 87px;
				border: 3px solid #feb97f;
				border-radius: 50%;
				.progress-text {
				  font-size: 24px;
				  width: 100%;
				  color: @orange;
				  line-height: 87px;
				  text-align: center;
				}
			  }
			}
			.item {
			  width: 80%;
			  .right{
				color:#333;
			  }
			  .time {
				padding-left: 0;
				background: none;
				a.link-right{
				  color:#333;
				  vertical-align: middle;
				}
			  }
			  .fund-list {
				margin-top: 20px;
				margin-bottom: 20px;
				overflow: hidden;
				zoom: 1;
				li {
				  border-right: 1px solid #E9E9E9;
				  padding-right: 40px;
				  float: left;
				  .text {
					color: #666666;
					margin-bottom: 0;
				  }
				  .show-money {
					color: @orange;
					font-size: 20px;
					margin-bottom: 0;
					font-weight: bold;
					text-align: center;
				  }
				  & + li {
					padding-left: 45px;
				  }
				  &:last-child {
					border: none;
				  }
				}
  
			  }
			  .address {
				padding-left: 0;
				background: none;
			  }
			  .apply {
				padding-left: 0;
				background: none;
			  }
			  .lowest-goal {
				padding-left: 0;
				background: none;
			  }
			  .voluntary {
				padding-left: 0;
				background: none;
			  }
			}
		  }
		  font-size: 14px;
		  .item {
			color: #999999;
  
			div {
			  margin: 10px 0;
			}
			a, span,.title {
			  &.show-money {
				font-weight: bold;
				font-size: 14px;
				color: @orange;
			  }
			}
			span{
			  vertical-align: middle;
			}
			.desc{
			  color: #333;
			}
			.detail-tips {
			  display: inline-block;
			  margin: 0;
			  .icon-warning {
				padding-right: 5px;
			  }
			  span {
				color: #999999;
			  }
			  &.not-line-tips{
				margin-left: 20px;
  
			  }
			}
			a {
			  color: #1e81cd;
			}
			a.link-right{
			  color:#333;
			}
  
			.time {
			  padding-left: 25px;
			  background: url(../../../../img/icon/time.png) no-repeat left center;
			}
			.address {
			  display: table;
			  padding-left: 25px;
			  background: url(../../../../img/icon/address2.png) no-repeat left top;
			  overflow: hidden;
			  .title{
				word-break: keep-all;
				white-space:nowrap;
				width: 70px;
				float: left;
				vertical-align: top;
			  }
			  .desc{
				width: 485px;
				vertical-align: top;
			  }
			}
			.apply-time{
			  padding-left: 25px;
			  background: url(../../../../img/icon/clock.png) no-repeat left center;
			  background-size: 15px 14px;
			}
			.apply {
			  padding-left: 25px;
			  background: url(../../../../img/icon/apply.png) no-repeat left center;
			}
			.lowest-goal {
			  padding-left: 25px;
			  background: url(../../../../img/icon/lowest-goal.png) no-repeat left center;
			}
			.voluntary {
			  padding-left: 25px;
			  background: url(../../../../img/activity-voluntary.png) no-repeat left center;
			}
			.price {
			  padding-left: 25px;
			  background: url(../../../../img/icon/price.png) no-repeat left center;
			  span {
				&.show-money {
				  color: @orange;
				  padding-right: 20px;
				}
			  }
			}
			.money {
			  color: @orange;
			}
		  }
		}
		.bottom {
		  position: absolute;
		  bottom: 0;
		  width: 100%;
		  height: 40px;
		  .btn {
			float: left;
			height: 35px;
			border-radius: 3px;
			line-height: 35px;
			font-size: 18px;
		  }
		  .share {
			float: left;
			margin-left: 15px;
			height: 35px;
			line-height: 35px;
			padding-top: 2.5px;
		  }
		  .tips-text {
			float: left;
			margin-left: 20px;
			line-height: 35px;
			span{
			  color:#999;
			  font-size: 12px;
			}
		  }
		  .qr-code-container {
			position: absolute;
			right: 0;
			bottom: 0;
			width: 190px;
			box-sizing: border-box;
			border: 1px solid #68c258;
			border-radius: 5px;
			padding: 6px;
			.img-box {
			  width: 70px;
			  height: 70px;
			  overflow: hidden;
			  float: left;
			}
			.tips {
			  position: absolute;
			  margin-left: 78px;
			  top: 50%;
			  transform: translateY(-50%);
			  color: #999;
			  > div{
				font-size: 12px;
				margin-bottom: 5px;
				line-height: 1.2;
				&:last-child{
				  margin-bottom: 0;
				}
  
			  }
			}
		  }
  
		}
  
	  }
  
	  .user-content-665 {
		width: 665px;
		height: 285px;
	  }
	}
	.detail {
	  border-top: 10px solid #f5f5f5;
	  .container {
		zoom: 1;
		overflow: hidden;
		.main-info {
		  font-size: 18px;
  
		  &.all-info {
			width: 100%;
			float: none;
		  }
		  width: 760px;
		  float: left;
		  .nav-tabs {
			border-bottom: 1px solid #e9e9e9;
			padding-top: 5px;
			> li {
			  text-align: center;
			  font-size: 18px;
			  display: inline-block;
			  padding: 10px 0px;
			  margin-right: 50px;
			  &.active {
				border-bottom: 2px solid @green;
				a {
				  color: @green;
				}
			  }
			}
		  }
		  .tab-content > .tab-pane {
			display: none;
			//padding-top: 20px;
		  }
		  .tab-content > .active {
			display: block;
		  }
		  .tab-content {
			.search-content{
			  margin-bottom: 10px;
			  margin-top: 25px;
			  overflow: hidden;
			  @height: 30px;
			  form{
				float: left;
				line-height: @height;
				height: @height;
				.search-form-wrapper{
				  line-height: @height;
				  height: @height;
				  input[type="text"]{
					background-color: #f5f5f5;
					border: 1px solid #e6e6e6;
					border-right: 0;
					line-height: @height;
					height: @height;
				  }
				  .btn-search{
					line-height: @height;
					height: @height;
				  }
				}
			  }
			  .btn{
				line-height: @height;
				height: @height;
				width: 100px;
				float: right;
			  }
			}
  
			.activity-project-list {
			  .item {
				zoom: 1;
				overflow: hidden;
				padding: 20px 0 14px 0;
				border-bottom: 1px dashed #e9e9e9;
				position: relative;
				img.avatar {
				  float: left;
				  margin-right: 20px;
				  width: 144px;
				  height: 96px;
				}
				.name {
				  float: left;
				  width: 78%;
				  height: 101px;
				  max-height: 101px;
				  overflow: hidden;
				  position: relative;
				  h4 {
					font-size: 16px;
					margin: 0 0 25px;
					.multi-text-overflow(1.5,2);
					height: 45px;
				  }
				  .p-bottom-box{
					position: absolute;
					bottom: 0;
					left: 0;
				  }
				  p {
					margin-bottom: 0;
				  }
				  .date {
					color: #999999;
					margin-bottom: 0;
				  }
				  .text-opacity-gray{
					color:#666666;
				  }
				  .title {
					color: #999999;
				  }
				  .money {
					color: @orange;
				  }
				}
				.status {
				  position: absolute;
				  right: 0;
				  top: 20px;
				  width: 75px;
				  height: 25px;
				  line-height: 25px;
				  -ms-text-align-last: right;
				  text-align: center;
				  border: 1px solid @green;
				  border-radius: 4px;
				  color: @green;
				}
			  }
			}
			.apply-record {
			  .item {
				zoom: 1;
				overflow: hidden;
				padding: 20px 0;
				border-bottom: 1px dashed #e9e9e9;
				position: relative;
				.avatar {
				  float: left;
				  margin-right: 20px;
				  .avatar(100px);
				  &.team-logo{
					.img-wrap(135px,100px);
					border-radius: 3px;
				  }
				  &.user-avatar{
					.avatar(68px);
				  }
  
				}
  
				.name {
				  display: table;
				  width: calc(100% - 120px);
				  width: 70%;
				  &.team-name{
					height: 95px;
				  }
				  h4 {
					font-size: 16px;
					margin: 0 0 20px 0;
				  }
				  p {
					margin-bottom: 0;
					color:#666;
					font-size: 14px
				  }
				  .slogan {
					width: 75%;
					.text-overflow;
				  }
				  .date {
					color: #999999;
					margin-bottom: 0;
				  }
				  .title {
					color: #999999;
				  }
				  .money {
					color: @orange;
				  }
				}
				.status {
				  position: absolute;
				  right: 0;
				  top: 50%;
				  transform: translateY(-50%);
				}
			  }
			  a.more, .btn_loadmore {
				display: block;
				margin: 30px auto 10px;
				padding: 10px 20px;
				border: 1px dashed #E9E9E9;
				width: 100px;
				text-align: center;
			  }
  
			  .participation {
				.item {
				  .status {
					color: @orange;
				  }
				}
			  }
			}
  
			.donate-record {
			  table {
				width: 100%;
				font-size: 14px;
				tr {
				  color: #333;
				  &.table-head{
					font-weight: bold;
				  }
				}
  
				.item {
				  color: #666;
				  border-bottom: 1px solid #e9e9e9;
				  td {
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					padding: 20px 10px 20px 0;
				  }
				}
			  }
  
			}
			.member {
			  .member-list {
				margin-top: 60px;
				overflow: hidden;
				zoom: 1;
				margin-right: -145px;
				li {
				  float: left;
				  width: 100px;
				  margin-right: 145px;
				  padding-bottom: 24px;
				  .avatar-box {
					.avatar(100px);
					margin-bottom: 20px;
					img {
					  width: 100%;
					  height: 100%;
					}
				  }
				  .member-name {
					color: #333;
					margin-bottom: 0;
					font-size: 16px;
					text-align: center;
					height: 40px;
					line-height: 1.2;
					overflow: hidden;
					.captain-flag {
					  color: @orange;
					  vertical-align: top;
					}
				  }
  
				}
			  }
			}
  
		  }
		}
		.sidebar {
		  width: 300px;
		  float: right;
		  .stat {
  
			//margin-top: 30px;
  
			.stat-panel-title {
			  color: #68c258;
			  font-size: 18px;
			  position: relative;
			  border-bottom: 1px solid #e9e9e9;
			  box-sizing: border-box;
			  line-height: 50px;
			  .title{
				height: 50px;
				line-height: 50px;
				border-bottom: 2px solid #68c258;
				display: inline-block;
			 }
			}
			.stat-panel-content {
			  border: 1px solid #E9E9E9;
			  background: #e5fce0;
			  color: #666666;
			  font-size: 16px;
			  padding-left: 22px;
			  padding-right: 22px;
			  margin-top: 20px;
			  .title {
				border-bottom: 1px solid #d1f6c1;
				.number {
				  color: #fd7400;
				  font-size: 18px;
				  line-height: 60px;
				  height: 60px;
				  font-weight: bold;
				}
				&:last-child {
				  border: none;
				}
			  }
			}
		  }
		  .activity-org {
			.stat-panel-title{
			  color: #68c258;
			  font-size: 18px;
			  position: relative;
			  border-bottom: 1px solid #e9e9e9;
			  box-sizing: border-box;
			  line-height: 50px;
			  margin-bottom: 25px;
			  h3.title{
				height: 50px;
				line-height: 50px;
				border-bottom: 2px solid #68c258;
				display: inline-block;
			  }
			}
			a.item {
			  position: relative;
			  color: #666;
			  font-size: 14px;
			  display: block;
			  overflow: hidden;
			  zoom: 1;
			  .org-logo {
				.img-wrap(80px, 80px);
				border: 1px solid #E9E9E9;
				float: left;
				margin-right: 50px;
				box-sizing: border-box;
				img{
				  max-height: 100%;
				}
			  }
			  .name {
				line-height: 1.7;
				float: left;
				height: 79px;
				width: 168px;
				border-bottom: 1px dashed #e9e9e9;
				h3 {
				  width: 95%;
				  margin-bottom: 13px;
				}
				p {
				  font-size: 12px;
				  color: #999999;
				  .show-num {
					color: @orange;
					font-weight: bold;
				  }
				}
			  }
			  .to-link {
				position: absolute;
				right: 0px;
				top: 50%;
				transform: translateY(-50%);
			  }
			}
  
		  }
		}
	  }
	}
  }
  
  .sx-form {
	.success-content {
	  text-align: center;
	  .icon-checked {
		margin-top: 130px;
		margin-bottom: 30px;
	  }
	  h1 {
		font-size: 24px;
		color: @green;
		font-weight: bold;
	  }
	  .article-content {
		p {
		  color: #666666;
		  font-size: 14px;
		  max-width: 535px;
		  margin: 45px auto 110px auto;
		  text-align: center;
		  .num {
			color: @green;
		  }
		  .site {
			color: @orange;
		  }
		}
	  }
  
	}
	.form-group {
	  &.checkbox-group{
		.wrapper{
		  .title{
			float: left;
		  }
		  .input{
			label{
			  display: block;
			  &+label{
				margin-top: 10px;
			  }
			}
		  }
		}
	  }
	  &.sx-jb-left-reminder{
		.wrapper{
		  display: table;
		  width: 100%;
		  .title{
			float: none;
			line-height: 1.2;
			display: table-cell;
			vertical-align: middle;
			padding-right: 10px;
		  }
		  .input{
			display: table-cell;
			vertical-align: middle;
		  }
		  .input{
			input[type=checkbox],input[type=radio]{
			  width: 17px;
			  height: 17px;
			  margin-left: 15px;
			}
			input[type=checkbox]:first-child,input[type=radio]:first-child{
			  margin-left: 0;
			}
		  }
		}
		.msg{
		  margin-left: 115px !important;
		}
	  }
	  .wrapper {
		.input{
		  &.warning{
			.bg('warning',png);
		  }
		}
		.radio-box {
		  margin-right: 40px;
		  display: inline-block;
		  input {
			width: 18px;
			height: 18px;
		  }
  
		  span {
			padding-left: 7px;
		  }
		}
		label {
		  input[type=checkbox] {
			width: 17px;
			height: 17px;
			vertical-align: middle;
		  }
  
		  .agreement {
			display: inline-block;
			font-size: 14px;
			color: #666666;
			.text-agreement {
			  padding-left: 5px;
			  color: @orange;
			  border-bottom: 1px dashed;
			}
		  }
		}
		.projcet-name {
		  font-size: 16px;
		  color: #333;
		  line-height: 45px;
		}
		.show-money {
		  font-size: 18px;
		  color: @orange;
		}
	  }
	  .msg {
		padding-bottom: 0;
		.tips-text {
		  padding-left: 20px;
		}
	  }
	}
	.pb150 {
	  padding-bottom: 150px;
	}
	.btn-text-left {
	  text-align: left;
	  margin-left: 100px;
	}
  }

//模拟下拉框
.select-search {
	position: relative;

	.search-meta {
		color: #ff0000;
		border-radius: 4px;
		border: 1px solid #ff0000;
		padding-left: 10px;
		padding-right: 15px;
		font-size: 16px;
		display: inline-block;
		height: 35px;
		line-height: 35px;
		box-sizing: border-box;
		cursor: pointer;

		span {
			vertical-align: top;
			height: 100%;
			max-width: 100px;
			white-space: nowrap;
			overflow: hidden;
		}

		&.active {
			&:after {
				transform: rotate(180deg);
			}
		}

		&:after {
			content: '';
			display: inline-block;
			.icon-select-drow;
			transform: translateY(-50%);
			margin-left: 20px;
			transition: all .3s;
			transform-origin: center 25%;
		}
	}

	.search-pannel {
		position: absolute;
		padding: 20px;
		left: 0;
		top: 45px;
		background-color: #fff;
		box-shadow: 0 3px 10px 0 rgba(7, 12, 1, 0.28);
		width: 200px;
		z-index: 3;
		display: none;
		border-radius: 4px;

		.search-list {
			font-size: 14px;
			overflow: hidden;
			margin-left: -10px;
			margin-bottom: -10px;

			li {
				cursor: pointer;
				float: left;
				padding-left: 15px;
				padding-right: 15px;
				height: 30px;
				line-height: 30px;
				border-radius: 4px;
				background-color: #e6e6e6;
				color: #e6e6e6;
				margin-left: 10px;
				margin-bottom: 10px;

				&.active {
					background-color: #ff0000;
					color: #fff;
				}
			}
		}
	}
}

//项目搜索框
.orguser-search {
	margin-bottom: 30px;
	font-size: 0;
	.clearfix;

	.select-search {
		float: left;
		margin-right: 20px;
	}

	select {
		border-radius: 4px;

		& + select {
			margin-left: 1px !important;
		}
	}

	select, input[type="text"], button {
		height: 35px;
		line-height: 35px;
		vertical-align: middle;
		float: left;
	}

	.search-keyword {
		margin-left: 10px;
		display: inline-block;
		vertical-align: middle;

		input[type=text] {
			width: 355px;
		}
	}

	select {
		width: 130px;
		height: 35px;
	}

	.btn-new-project {
		float: right;
		width: 145px;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		text-align: center;
	}
}

///项目搜索框
.search {
	margin-bottom: 30px;
	font-size: 0;
	.clearfix;

	.select-search {
		float: left;
		margin-right: 20px;
	}

	select, input[type="text"], button {
		height: 35px;
		line-height: 35px;
		vertical-align: middle;
		float: left;
		padding: 0 20px;
	}

	.search-keyword {
		display: inline-block;

		input[type=text] {
			background-color: #fff;
			width: 355px;
			border: 1px solid;
			border-right: 0;
		}
	}

	select {
		.icon-select-drow;
		width: 130px;
		height: 35px;
		background-color: #fff;
		color: @red;
		border-radius: 4px;
		border: 1px solid;
		margin-right: 20px;
		font-size: 16px;
		appearance: none;
		background-position: 85px center;
	}

	.btn-new-project {
		float: right;
		width: 145px;
		height: 35px;
		line-height: 35px;
		font-size: 18px;
		text-align: center;
	}
}

//搜索关键词
.search-keyword {
	overflow: hidden;

	input[type="text"], button {
		float: left;
		height: 35px;
		line-height: 35px;
		vertical-align: middle;
	}

	input[type="text"] {
		border-radius: 4px 0 0 4px;
	}

	button {
		width: 65px;
		border-radius: 0 4px 4px 0;
		border: unset;
		background-color: @green;
		position: relative;

		.icon-search {
			.abs-center;
		}
	}
}


//分页
.pagination {
	text-align: center;
	font-size: 14px;

	&.pagination-padding {
		padding-top: 20px;
		padding-bottom: 20px;
	}

	.page-nav {
		margin-left: 10px;
		display: inline-block;
		vertical-align: middle;

		&:first-child {
			margin-left: 0;
			margin-right: 10px;
		}

		&.page-fixed {
		}

		&.page-nearby {
			color: #fff;
			height: 30px;
			width: 30px;
			border-radius: 4px;
			line-height: 30px;
			background-color: @green;

			&.disabled {
				color: @light-black;
				background-color: #e3e3e3;
			}
		}
	}

	.number {
		height: 30px;
		line-height: 30px;
		margin: 0 5px;
		text-align: center;
		width: 20px;
		display: inline-block;

		&.on {
			color: @green;
			font-weight: bold;
			position: relative;

			&:after {
				content: '';
				border-bottom: 2px solid @green;
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}

	}

	.omit {
		margin-left: 10px;
	}

	.total {
		color: @gray;
		margin-left: 20px;
	}
}

.donation-detail {
	.main-info {
		> .title {
			margin-bottom: 30px;
			padding: 15px 0;
			overflow: hidden;
			color: @dark-red;
			border-bottom: 1px solid @dark-red;

			.meta {
				float: left;
				width: 90px;
				font-size: 18px;
				color: @dark-red;
			}

			.content {
				line-height: 1.5;
				margin-left: 90px;
				font-size: 16px;
				color: @black;
			}
		}

		.border-bottom{
			border-bottom: 1px solid @border;
		}

		.form-group, .form-control {
			@left: 105px;
			margin-bottom: 30px;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			width: 100%;
			overflow: visible;

			.wrapper {
				overflow: visible;
				width: 100%;
			}

			.title, .field-name {
				width: @left;
				line-height: 1.2;
				padding-right: 5px;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}

			.input, .form-field {
				margin-left: @left;
			}

			.msg {
				margin-left: @left;
			}

			&.field-remark {
				margin-bottom: 0;

				.msg {
					margin-top: 20px;
					padding-bottom: 30px;
				}
			}

			&.form-invoice {
				padding: 30px 0;
			}

			&.field-inline {
				line-height: 15px;

				.title {
					line-height: 1.2;
					height: auto;
				}

				.input {
					input {
						margin-right: 5px;
					}

					label {
						display: inline-block;

						& + label {
							margin-left: 45px;
						}
					}
				}
			}
		}


		.btn-submit {
			width: 145px;
			height: 35px;
			line-height: 35px;
		}
	}
}

//机构添加项目进度
.project-progress {
	overflow: hidden;
	text-align: center;

	.step {
		position: relative;
		width: 33.333%;
		float: left;
		height: 50px;
		line-height: 50px;
		font-size: 16px;
		background-color: #eeeeee;
		color: @light-black;

		&.on {
			&:after {
				background-color: @dark-red;
			}
		}

		&:after {
			content: '';
			position: absolute;
			box-sizing: border-box;
			right: -18px;
			top: 7px;
			width: 36px;
			height: 36px;
			border-right: 1px solid #fff;
			border-top: 1px solid #fff;
			transform: rotate(45deg);
			background-color: #eeeeee;
			z-index: 1;
		}

		.number {
			line-height: 16px;
			height: 16px;
			width: 16px;
			border-radius: 50%;
			color: #fff;
			font-size: 14px;
			background-color: #999999;
		}

		&.pass{
			.number {
				background-color: @dark-red;
				color: #fff;
			}
		}

		&.on {
			background-color: @dark-red;
			color: #fff;

			.number {
				background-color: #fff;
				color: @dark-red;
			}
		}
	}
}

//表单类
.form-group,.form-control{
	margin-bottom: 20px;
	overflow: hidden;
	.wrapper{
		overflow: hidden;
	}
	.title,.field-name{
		height: 45px;
		line-height: 45px;
		text-align: right;
		width: 130px;
		font-size: 14px;
		float: left;
		.text-orange{
			padding-right: 3px;
		}
		.red{
			color: #fd7400;
		}
		&.top{
			line-height: 1.5;
			height: auto;
		}
	}
	&.checkbox-group{
		line-height: 1.5;
		height: auto;
		.title,.input,.field-name,.form-field{
			line-height: 1.5;
			height: auto;
		}
		.input,.form-field{
			margin-right: -15px;
		}
		label{
			margin-right: 15px;
			display: inline-block;
			cursor: pointer;
			line-height: 1.5;
			.input{
				span{
					margin-left: 3px;
					font-size: 14px;
					color: @black;
					vertical-align: middle;
				}
			}
		}
	}
	.input,.form-field{
		position: relative;
		.tips{
			margin-bottom: 0;
			line-height: 45px;
		}
		input[type=text],textarea,select{
			width: 100%;
			box-sizing: border-box;
			border-radius: 4px;
		}
		.input-select-box{
			height: 45px;
			line-height: 45px;
			label{
				& + label{
					margin-left: 55px;
				}
			}
			input[type=checkbox],input[type=radio]{
				margin-right: 3px;
				width: 20px;
				height: 20px;
			}
		}
		textarea{
			height: 120px;
			border-radius: 4px;
		}
		.unit{

		}
		.tips-text{
			color: @gray;
			font-size: 14px;
		}
	}
	.text{
		padding-top: 10px;
		line-height: 1.8;
	}
	.img{
		width: 200px;
	}
	.msg,.checkbox-msg{
		margin-left: 140px;
		margin-top: 5px;
		position: relative;
	}
	.msg{
		display: flex;
		align-items: center;
		font-size: 14px;
		color: @gray;
		.icon-warning{
			.icon-msg;
		}
		.tips-text, .desc{
			padding-left: 15px;
		}
	}
	.arrd-select-wrap{
		margin-bottom: 5px;
	}
	.checkbox-msg{
		input[type="checkbox"]{
			width: 16px;
			height: 16px;
		}
		.desc{
			font-size: 14px;
			color: @light-black;
			line-height: 1.5;
		}
	}

	//两个一起的时间
	&.select-time{
		>.input{
			.form-group{
				@width: 75px;
				margin-bottom: 0;
				float: left;
				.title{
					width: @width;
					text-align: left;
					white-space: nowrap;
				}
				.input{
					margin-left: @width;
				}
				& + .form-group{
					margin-left: 45px;
				}
			}
		}
	}



	//选择框
	&.checkbox-select{
		@line-height: 20px;
		.title{
			height: @line-height;
			line-height: @line-height;
		}
		.input{
			height: @line-height;
			line-height: @line-height;
			label{
				display: inline-block;
				vertical-align: top;
				height: @line-height;
				line-height: @line-height;
				input[type='radio'],input[type='checkbox']{
					width: 20px;
					height: 20px;
				}
				span,input[type='radio'],input[type='checkbox']{
					vertical-align: top;
				}
				span{
					height: @line-height;
					line-height: @line-height;
					margin-left: 10px;
				}
				& + label{
					margin-left: 45px;
				}
			}
		}
		.msg{
		}
	}
}

.page-sx{
	input[type=text], input[type=password], input[type=number], .select-default, select, .addr-select {
		height: 45px;
		line-height: 45px;
		color: #666;
		font-size: 14px;
		background-color: #eeeeee;
		border: none;
		outline: none;
		padding: 0 20px;
		border-radius: 4px;
	}

	textarea {
		background-color: #eeeeee;
		color: #666;
		padding: 10px;
		outline: none;
		border: none;
		font: 14px/1.4286 arial, "Hiragino Sans GB", "Microsoft YaHei", "WenQuanYi Micro Hei", sans-serif;
	}

	.ossuploader-add{
		background-color: #eeeeee;
		border: 0;
		border-radius: 4px;
	}

	.msg-page{
		box-sizing: content-box;
		.msg-content{
			padding-top: 80px;
			margin-left: auto;
			margin-right: auto;
			.title{
				font-size: 26px;
				color: @dark-red;
				margin-bottom: 35px;
				margin-top: 40px;
			}
			.desc{
				font-size: 18px;
				color: @light-black;
				line-height: 1.8;
			}
			.btn-group{
				margin-top: 80px;
			}
		}
	}
}

.ossuploader-add{
	justify-content: center;
	i{
		width: 40px !important;
		height: 40px !important;
	}
	p{
		color: @light-black;
		font-size: 14px !important;
	}
}


//发布活动
.sx-release-activity {
	.project-progress{
		margin-bottom: 25px;
	}
	.form-group {
		margin-bottom: 30px;

		> .msg {
			margin-top: 10px;
		}

		&.activity-address {
			.input {
				.addr-select {
					width: 150px;
					margin-right: 10px;
				}

				input[name="address"] {
					width: 575px;
				}
			}
		}

		//选择框
		&.checkbox-select {
			@line-height: 20px;

			.title {
				height: @line-height;
				line-height: @line-height;
			}

			.input {
				height: @line-height;
				line-height: @line-height;

				label {
					display: inline-block;
					vertical-align: top;
					height: @line-height;
					line-height: @line-height;

					input[type='radio'], input[type='checkbox'] {
						width: 20px;
						height: 20px;
					}

					span, input[type='radio'], input[type='checkbox'] {
						vertical-align: top;
					}

					span {
						height: @line-height;
						line-height: @line-height;
						margin-left: 10px;
					}

					& + label {
						margin-left: 45px;
					}
				}
			}

			.msg {
			}
		}

		//队伍人数
		&.group-number {
			.input {
				overflow: hidden;

				.unit-wrapper {
					width: 425px;
					float: left;
				}

				.desc {
					width: 50px;
					float: left;
					text-align: center;
					line-height: 45px;
				}
			}
		}


		&.sign-type {
			margin-top: -12px;
		}

		&.pay-account {
			.input {
				font-size: 0;

				select {
					width: 296px;
					vertical-align: middle;

					& + select {
						margin-left: 5px;
					}
				}
			}
		}

		&.money-limit {
			.input {
				height: 25px;

				.money-collect {
					input {
						width: 95px;
						height: 25px;
						line-height: 25px;
						background-color: #fff;
						border: 1px solid @border;
						box-sizing: border-box;
						padding: 0 10px;
						margin-right: 10px;
					}

					margin-left: 15px;
				}
			}
		}

	}

	//报名类型
	.apply-type-list {
		.header {
			padding-top: 12px;
			margin-bottom: 20px;
			overflow: hidden;

			.item {
				float: left;

				&.type-name {
					width: 360px;
				}

				&.number, &.money {
					width: 235px;
					margin-left: 20px;
				}
			}
		}

		.list {
			.item {
				overflow: hidden;
				margin-bottom: 20px;

				.type-name, .number, .money {
					float: left;
				}

				.type-name {
					width: 360px;
				}

				.number, .money {
					width: 235px;
					margin-left: 20px;
				}

				.operation {
					float: left;
					width: 50px;
					text-align: center;
					line-height: 45px;
					height: 45px;
					display: table;

					a {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
		}

		.bottom {
			overflow: hidden;

			.add {
				float: left;
			}

			.msg {
				float: left;
				margin-left: 20px;
				margin-top: 0;
				line-height: 32px;

				.desc {
					line-height: 32px;
				}
			}
		}
	}



	.btn-group{
		justify-content: center;
		.btn{
			height: 50px;
			line-height: 50px;
			font-size: 18px;
			width: 190px;
			flex-grow: 0;
		}
	}
}

.sx-container{
	padding: 0 20px 85px;
	box-sizing: border-box;
}

.page-with-space-top-bottom{
	padding-top: 30px;
	padding-bottom: 145px;
}


.apply-form-set {
	margin: 50px 0 0;
	padding: 50px 0;
	border-top: 1px solid @border;
	border-bottom: 1px solid @border;

	.multip-filed {
		display: none;
	}

	.desc-content {
		text-align: center;

		h3 {
			font-size: 20px;
			font-weight: bold;
			color: #333;
			margin: 10px 0;
		}
	}

	.apply-form-wrapper {
		overflow: hidden;
		zoom: 1;
		margin-top: 40px;
	}

	.apply-form-list {
		float: left;
		width: 730px;
		border: 1px solid @border;
		padding: 20px;
		box-sizing: border-box;

		h3.inf-title {
			font-size: 14px;
			font-weight: bold;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid @border;

			span {
				color: @gray;
				vertical-align: middle;
				display: inline;
			}
		}

		.base-inf {
			.base-info-list {
				margin: 0 -20px;

				li {
					padding: 0 20px;
					margin-bottom: 25px;
					overflow: hidden;

					&.off {
						background-color: #eeeeee;
					}

					.checbox-wrapper {
						float: left;
						height: 45px;
						line-height: 45px;
						width: 65px;
						font-size: 14px;
						color: @light-black;

						input[type=checkbox] {
							vertical-align: middle;
							width: 17px;
							height: 17px;
							margin-right: 10px;
						}
					}

					.form-group {
						margin-bottom: 0;
						@width: 80px;

						.title {
							width: @width;
						}

						.input {
							margin-left: @width;

							.tips {
								color: @gray;
							}
						}
					}
				}
			}
		}

		.extend-inf {
			padding-top: 20px;

			.icon-list {
				& + .item {
					margin-top: 20px;
				}
			}

			.item {
				padding-bottom: 20px;
				border-bottom: 1px solid #e8e8e8;
				overflow: hidden;
				zoom: 1;

				& + .item {
					margin-top: 20px;
				}

				.item-name {
					float: left;
					width: 120px;
					text-align: right;
					line-height: 45px;
					font-size: 16px;
				}

				.item-content {
					float: right;
					width: 560px;
					font-size: 0;

					label {
						float: left;
						margin-top: 12px;
						display: inline-block;
					}

					.is-need {
						font-size: 14px;
					}

					.delete-form-item {
						float: right;
						margin-top: 14px;
						margin-right: 14px;
					}
				}

				input {
					margin-right: 10px;

					&[type=checkbox] {
						margin-right: 5px;
					}
				}

				label {
					margin-right: 10px;
				}

				.name {
					width: 160px;
				}

				.desc {
					width: 290px;
				}

				.option {
					margin-top: 10px;
					zoom: 1;
					overflow: hidden;

					h4 {
						float: left;
						height: 42px;
						line-height: 42px;
						margin-right: 10px;
					}

					.list {
						float: left;
						width: 468px;

						.item {
							display: inline-block;
							border: none;
							margin: 0 5px;
							padding: 10px 10px 0 0;
							position: relative;
							vertical-align: middle;

							input {
								width: 190px;
								height: 40px;
								line-height: 40px;
								margin-right: 0;
							}

							a.delete-form-option {
								position: absolute;
								right: 0;
								top: 0;
							}
						}

						a {
							display: inline-block;
							margin: 5px;
						}
					}
				}
			}
		}


	}

	.form-item-list {
		float: right;
		width: 260px;
		border: 1px solid #e8e8e8;
		padding: 20px;
		box-sizing: border-box;

		h3 {
			font-size: 16px;
			padding-bottom: 20px;
			border-bottom: 1px solid #e8e8e8;
		}

		.item {
			margin-top: 20px;
			padding: 10px;
			.bg(form-item-add, png, 192px center, unset);
			background-color: #eeeeee;
			color: #666;
			font-size: 14px;

			a {
				display: block;
			}

			img {
				display: inline-block;
				margin-right: 10px;
				vertical-align: middle;
			}
		}
	}
}
